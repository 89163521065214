import React, { useCallback, useRef, useState, useEffect } from "react";
import { toast } from "react-toastify";
import {
  Button,
  Typography,
  Drawer,
  Dialog,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  Controls,
  Background,
  Handle,
  Position,
  isNode,
  getConnectedEdges,
  getIncomers,
  getOutgoers,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";

import { useConversationContext } from "../../contexts/ConversationContextProvider";

import "../../index.css";
const initialNodes = [
  {
    id: "0",
    type: "customNode",
    data: { label: "Node" },
    position: { x: 0, y: 10 },
  },
];

const CustomNode = ({ data, id, onClick }) => (
  <div onClick={() => onClick(id)}>
    <Handle type="target" className="bg-slate-600" position={Position.Top} />
    <div className="text-[9px]">{data.label}</div>
    <Handle type="source" position={Position.Bottom} />
  </div>
);

const Popup = ({ nodeId, label, onClose, onSave }) => {
  const [newLabel, setNewLabel] = useState(label);
  const handleSave = () => {
    onSave(nodeId, newLabel);
    onClose();
  };

  useEffect(() => {
    setNewLabel(label);
  }, [label]);

  return (
    <div className="popup flex flex-col gap-2 justify-start">
      <textarea
        className="h-32 border-2 border-solid p-2 w-full"
        value={newLabel}
        onChange={(e) => setNewLabel(e.target.value)}
      />
      <div className="flex flex-row justify-end gap-2">
        <Button
          type="button"
          variant="filled"
          color="green"
          onClick={handleSave}
        >
          Save
        </Button>
        <Button type="button" variant="filled" color="red" onClick={onClose}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

let id = 1;
const getId = () => `${id++}`;
const nodeOrigin = [0.5, 0];

const MessageFlow = () => {
  const reactFlowWrapper = useRef(null);
  const [popup, setPopup] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const { screenToFlowPosition } = useReactFlow();
  const { saveMessageFlowDesign } = useConversationContext();

  const onConnect = useCallback(
    (params) => setEdges((eds) => addEdge(params, eds)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleSave = (nodeId, newLabel) => {
    setNodes((nds) =>
      nds.map((node) =>
        node.id === nodeId
          ? { ...node, data: { ...node.data, label: newLabel } }
          : node
      )
    );
    setOpenDrawer(false);
  };

  const onConnectEnd = useCallback(
    (event, connectionState) => {
      // when a connection is dropped on the pane it's not valid
      if (!connectionState.isValid) {
        // we need to remove the wrapper bounds, in order to get the correct position
        const id = getId();
        let nodeExists = nodes.find((x) => x.id === id);
        if (!nodeExists) {
          const { clientX, clientY } =
            "changedTouches" in event ? event.changedTouches[0] : event;
          const newNode = {
            id,
            position: screenToFlowPosition({
              x: clientX,
              y: clientY,
            }),
            type: "customNode",
            data: { label: `Node ${id}` },
            origin: [0.5, 0.0],
          };

          setNodes((nds) => nds.concat(newNode));
          setEdges((eds) =>
            eds.concat({ id, source: connectionState.fromNode.id, target: id })
          );
        } else {
          alert("Please try again");
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [screenToFlowPosition]
  );

  const handleNodeClick = (nodeId) => {
    const node = nodes.find((n) => n.id === nodeId);
    setPopup({ nodeId, label: node.data.label });
    setOpenDrawer(true);
  };

  const handleSaveDesign = () => {
    saveMessageFlowDesign({
      Nodes: nodes,
      Edges: edges,
    });
  };

  const onDeleteNodeConfirm = () => {
    if (selectedNodes.length > 0) {
      setOpenDeleteDialog(true);
    } else {
      toast.error("Please select any node to delete");
    }
  };

  const onNodesDelete = useCallback(() => {
    const deleted = nodes.filter((x) => x.selected === true);
    setNodes((nodes) => nodes.filter((x) => x.id !== deleted[0].id));
    setEdges(
      deleted.reduce((acc, node) => {
        const incomers = getIncomers(node, nodes, edges);
        const outgoers = getOutgoers(node, nodes, edges);
        const connectedEdges = getConnectedEdges([node], edges);

        const remainingEdges = acc.filter(
          (edge) => !connectedEdges.includes(edge)
        );

        const createdEdges = incomers.flatMap(({ id: source }) =>
          outgoers.map(({ id: target }) => ({
            id: `${source}->${target}`,
            source,
            target,
          }))
        );

        return [...remainingEdges, ...createdEdges];
      }, edges)
    );
    setOpenDeleteDialog(false);
    setSelectedNodes([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nodes, edges]);

  const onNodeClick = useCallback((event, element) => {
    if (isNode(element)) {
      setNodes((els) =>
        els.map((el) => ({
          ...el,
          style: {
            ...el.style,
            border:
              el.id === element.id ? "1px solid red" : "1px solid #25d366",
          },
          selected: el.id === element.id,
        }))
      );

      setSelectedNodes([element?.id]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col justify-start gap-1">
      <div className="flex flex-row justify-between gap-2">
        <div className="flex flex-col gap-1">
          <Typography variant="h5" className="pb-2 text-green-600">
            Design message flow
          </Typography>
          <div className="text-green-700 text-[14px]">
            1) Click on the node text to change it.
          </div>
          <div className="text-green-700 text-[14px]">
            2) Click on a node to select it. If a node is selected, its border
            color should be red.
          </div>
        </div>
        <div className="flex flex-row justify-end self-end gap-2">
          <Button
            onClick={handleSaveDesign}
            type="button"
            variant="filled"
            className="w-32 h-10"
            color="green"
          >
            Save
          </Button>
          <Button
            onClick={onDeleteNodeConfirm}
            type="button"
            variant="filled"
            className="w-32 h-10"
            color="red"
          >
            Delete Node
          </Button>
        </div>
      </div>
      <div className="wrapper" ref={reactFlowWrapper}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          nodeTypes={{
            customNode: (props) => (
              <CustomNode {...props} onClick={handleNodeClick} />
            ),
          }}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onConnectEnd={onConnectEnd}
          onNodeClick={onNodeClick}
          fitView
          fitViewOptions={{ padding: 2 }}
          nodeOrigin={nodeOrigin}
        >
          <Background />
          <Controls />
        </ReactFlow>
        <Drawer
          placement="right"
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          size="30vw"
          className="rounded-t-xl z-[9996] p-3"
        >
          <div className="flex flex-col">
            <Typography variant="h5" className="pb-2" color="blue-gray">
              Update Label
            </Typography>
            <Popup
              nodeId={popup?.nodeId}
              label={popup?.label}
              onClose={() => {
                setPopup(null);
                setOpenDrawer(false);
              }}
              onSave={handleSave}
            />
          </div>
        </Drawer>
      </div>

      <Dialog
        open={openDeleteDialog}
        size="sm"
        className="w-[20%]"
        handler={() => setOpenDeleteDialog(!openDeleteDialog)}
      >
        <DialogHeader>
          <div className="text-red-700">
            Do you want to delete this selected node?
          </div>
        </DialogHeader>

        <DialogFooter className="flex flex-row justify-end gap-2">
          <Button
            type="button"
            onClick={onNodesDelete}
            variant="filled"
            color="green"
          >
            Confirm
          </Button>
          <Button
            type="button"
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
            variant="filled"
            color="red"
          >
            Cancel
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
};
export default MessageFlow;
