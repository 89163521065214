import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Select,
  Option,
  Button,
  IconButton,
  Typography,
  Card,
  CardHeader,
  CardBody,
} from "@material-tailwind/react";
import { HiMiniPencil } from "react-icons/hi2";

import { useFollowupContext } from "../../contexts/FollowupContextProvider";
import { colorsConfig } from "../../config/colorsConfig";
import { useUserContext } from "../../contexts/UserContextProvider";

const AssignedFollowup = ({ contactNumber }) => {
  const {
    apiCallFetchContactFollowup,
    followupList,
    updateContactFollowupStatus,
    updateContactFollowup,
  } = useFollowupContext();

  const { userAgentsList, apiCallGetUserAgents } = useUserContext();

  const [isUpdateFollowupPopup, setIsUpdateFollowupPopup] = useState(false);
  const [isStatusUpdateFollowupPopup, setIsStatusUpdateFollowupPopup] =
    useState(false);
  const [followupStatus, setFollowupStatus] = useState("");
  const [followupBy, setFollowupBy] = useState(0);
  const [followupCheck, setFollowupCheck] = useState("");
  const [followupRemarks, setFollowupRemarks] = useState("");
  const [followupId, setFollowupId] = useState(0);

  const addHoursToCurrentDateTime = (hours) => {
    let date = new Date();

    // Convert hours to minutes (e.g., 2.5 hours * 60 = 150 minutes)
    const totalMinutes = hours * 60;
    date.setMinutes(date.getMinutes() + totalMinutes);
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

    return date.toISOString().substring(0, 16);
  };

  const [selectedFollowupDateTime, setSelectedFollowupDateTime] = useState(
    addHoursToCurrentDateTime(1)
  );

  const followupStatusList = [
    { flpStatus: "~ Select ~", value: "" },
    { flpStatus: "Pending", value: "Pending" },
    { flpStatus: "Done", value: "Done" },
    { flpStatus: "Cancelled", value: "Cancelled" },
  ];

  const followupTableColumn = [
    {
      name: "Contact Number",
      selector: (row) => row?.contactNumber,
      sortable: true,
    },
    {
      name: "Follow-Up On",
      selector: (row) => row?.followUpOn,
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row?.followUpRemarks,
      sortable: true,
    },
    {
      name: "Follow-Up By",
      selector: (row) => {
        return (
          <>
            <span>{row?.followupBy}</span>{" "}
            <span className="text-green-700 font-bold">
              (
              {row?.agentTypeOfFollowupBy === 1
                ? "Admin"
                : row?.agentTypeOfFollowupBy === 2
                ? "Superadmin"
                : "Agent"}
              )
            </span>
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => {
        return (
          <>
            <span>{row?.followupCreatedBy}</span>{" "}
            <span className="text-green-700 font-bold">
              (
              {row?.agentTypeOfCreatedBy === 1
                ? "Admin"
                : row?.agentTypeOfCreatedBy === 2
                ? "Superadmin"
                : "Agent"}
              )
            </span>
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Follow-Up Status",
      selector: (row) => row?.followUpStatus,
      sortable: true,
    },
    {
      name: "Follow-Up Remarks",
      selector: (row) => row?.followupTakenComments,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        if (row?.followUpStatus === "Pending") {
          return (
            <button
              type="button"
              onClick={() => {
                setFollowupId(row?.Id);
                setIsStatusUpdateFollowupPopup(true);
              }}
            >
              <IconButton variant="text">
                <HiMiniPencil className="h-4 w-4" />
              </IconButton>
            </button>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  const selfCreatedFollowupTableColumn = [
    {
      name: "Contact Number",
      selector: (row) => row?.contactNumber,
      sortable: true,
    },
    {
      name: "Follow-Up On",
      selector: (row) => row?.followUpOn,
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row?.followUpRemarks,
      sortable: true,
    },
    {
      name: "Follow-Up By",
      selector: (row) => {
        return (
          <>
            <span>{row?.followupBy}</span>{" "}
            <span className="text-green-700 font-bold">
              (
              {row?.agentTypeOfFollowupBy === 1
                ? "Admin"
                : row?.agentTypeOfFollowupBy === 2
                ? "Superadmin"
                : "Agent"}
              )
            </span>
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => {
        return (
          <>
            <span>{row?.followupCreatedBy}</span>{" "}
            <span className="text-green-700 font-bold">
              (
              {row?.agentTypeOfCreatedBy === 1
                ? "Admin"
                : row?.agentTypeOfCreatedBy === 2
                ? "Superadmin"
                : "Agent"}
              )
            </span>
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Follow-Up Status",
      selector: (row) => row?.followUpStatus,
      sortable: true,
    },
    {
      name: "Follow-Up Remarks",
      selector: (row) => row?.followupTakenComments,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        if (row?.followUpStatus === "Pending") {
          return (
            <button
              type="button"
              onClick={() => {
                setFollowupId(row.Id);
                setFollowupBy(row?.followUpById);
                setFollowupRemarks(row?.followUpRemarks);
                const followupDate = new Date(row?.followUpOn);
                followupDate.setMinutes(
                  followupDate.getMinutes() - followupDate.getTimezoneOffset()
                );
                setSelectedFollowupDateTime(
                  followupDate.toISOString().substring(0, 16)
                );
                setIsUpdateFollowupPopup(true);
              }}
            >
              <IconButton variant="text">
                <HiMiniPencil className="h-4 w-4" />
              </IconButton>
            </button>
          );
        } else {
          return <></>;
        }
      },
    },
  ];

  const FollowupHistoryTableColumn = [
    {
      name: "Contact Number",
      selector: (row) => row?.contactNumber,
      sortable: true,
    },
    {
      name: "Follow-Up On",
      selector: (row) => row?.followUpOn,
      sortable: true,
    },
    {
      name: "Note",
      selector: (row) => row?.followUpRemarks,
      sortable: true,
    },
    {
      name: "Follow-Up By",
      selector: (row) => {
        return (
          <>
            <span>{row?.followupBy}</span>{" "}
            <span className="text-green-700 font-bold">
              (
              {row?.agentTypeOfFollowupBy === 1
                ? "Admin"
                : row?.agentTypeOfFollowupBy === 2
                ? "Superadmin"
                : "Agent"}
              )
            </span>
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Created By",
      selector: (row) => {
        return (
          <>
            <span>{row?.followupCreatedBy}</span>{" "}
            <span className="text-green-700 font-bold">
              (
              {row?.agentTypeOfCreatedBy === 1
                ? "Admin"
                : row?.agentTypeOfCreatedBy === 2
                ? "Superadmin"
                : "Agent"}
              )
            </span>
          </>
        );
      },
      sortable: true,
    },
    {
      name: "Follow-Up Status",
      selector: (row) => row?.followUpStatus,
      sortable: true,
    },
    {
      name: "Follow-Up Remarks",
      selector: (row) => row?.followupTakenComments,
      sortable: true,
    },
  ];

  const handleUpdateFollowupStatus = (event) => {
    event.preventDefault();

    if (followupId > 0) {
      updateContactFollowupStatus({
        followupId: followupId,
        followupStatus: followupStatus,
        followupRemarks: followupRemarks,
      });
      setIsStatusUpdateFollowupPopup(false);
    }
  };

  const handleFollowUpOnUpdate = (event) => {
    event.preventDefault();

    if (followupBy === 0) {
      setFollowupCheck("Please select the Follow-Up by");
      return;
    } else if (followupBy > 0 && followupId > 0 && followupRemarks !== "") {
      updateContactFollowup({
        followupId: followupId,
        followupBy: followupBy,
        followupOn: selectedFollowupDateTime,
        followupRemarks: followupRemarks,
      });
      setIsUpdateFollowupPopup(false);
      setFollowupBy("");
      setFollowupRemarks("");
      setFollowupCheck("");
      setFollowupId(0);
    }
    // else {
    //   console.log("followupBy", followupBy);
    //   console.log("followupId", followupId);
    //   console.log("selectedFollowupDateTime", selectedFollowupDateTime);
    //   console.log("followupRemarks", followupRemarks);
    // }
  };

  const handleScroll = (e) => {
    // Prevent scrolling from bubbling up to parent elements
    e.stopPropagation();
  };

  useEffect(() => {
    apiCallFetchContactFollowup("assignedFollowup", contactNumber, "");
    apiCallGetUserAgents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStatusUpdateFollowupPopup, isUpdateFollowupPopup, contactNumber]);

  return (
    <>
      <Card className="h-full w-full">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
            <div>
              <Typography variant="h5" color="blue-gray">
                Follow-Ups
              </Typography>
              <p className="mt-1">
                WaBM Follow-Ups for selected contact all at one place
              </p>
            </div>
          </div>
        </CardHeader>

        <CardBody
          onScroll={handleScroll}
          style={{ height: "80vh" }}
          className="overflow-y-scroll space-y-5"
        >
          <div>
            <h2 className="text-wabmAiBlue">Pending Assigned Follow-Ups</h2>
            <div className="my-2 rounded-md">
              <DataTable
                columns={followupTableColumn}
                data={followupList?.filter(
                  (x) => x.followupType === "assigned"
                )}
                customStyles={assignedFollowupTableStyles}
                fixedHeader
                pagination
                fixedHeaderScrollHeight="60vh"
              />
            </div>
          </div>
          <div>
            <h2 className="text-wabmAiBlue">Created Follow-Ups</h2>
            <div className="my-2 rounded-md">
              <DataTable
                columns={selfCreatedFollowupTableColumn}
                data={followupList?.filter((x) => x.followupType === "created")}
                customStyles={assignedFollowupTableStyles}
                fixedHeader
                pagination
                fixedHeaderScrollHeight="60vh"
              />
            </div>
          </div>
          <div>
            <h2 className="text-wabmAiBlue">Follow-Ups History</h2>
            <div className="my-2 rounded-md">
              <DataTable
                columns={FollowupHistoryTableColumn}
                data={followupList?.filter((x) => x.followupType === "History")}
                customStyles={assignedFollowupTableStyles}
                fixedHeader
                pagination
                fixedHeaderScrollHeight="60vh"
              />
            </div>
          </div>
        </CardBody>

        <Dialog
          className="w-72"
          open={isStatusUpdateFollowupPopup}
          handler={() =>
            setIsStatusUpdateFollowupPopup(!isStatusUpdateFollowupPopup)
          }
        >
          <span className="flex justify-between gap-2">
            <DialogHeader>Want to update Follow Up Status?</DialogHeader>
          </span>
          <form onSubmit={handleUpdateFollowupStatus}>
            <DialogBody className="space-y-2">
              <div className="flex flex-col justify-start gap-4">
                <Select
                  label="Follow-Up Status"
                  variant="standard"
                  color="blue"
                  required
                  onChange={(value) => {
                    setFollowupStatus(value);
                  }}
                  className="my-input-box-1"
                >
                  {followupStatusList.map((status) => (
                    <Option
                      key={status.value}
                      value={status.flpStatus}
                      className={`hover:bg-blue-300 focus:bg-blue-300`}
                    >
                      {status.flpStatus}
                    </Option>
                  ))}
                </Select>
                <Input
                  variant="standard"
                  label="Follow-Up Remarks"
                  placeholder="Eg. Check if customer has seen your proposal"
                  required
                  onChange={(e) => setFollowupRemarks(e.target.value)}
                />
              </div>
            </DialogBody>
            <DialogFooter>
              <Button
                type="button"
                variant="text"
                color="red"
                onClick={() => {
                  setIsStatusUpdateFollowupPopup(false);
                }}
                className="mr-1"
              >
                <span>Cancel</span>
              </Button>
              <Button type="submit" variant="gradient" color="green">
                <span>Confirm</span>
              </Button>
            </DialogFooter>
          </form>
        </Dialog>

        <Dialog
          className="w-72"
          open={isUpdateFollowupPopup}
          handler={() => setIsUpdateFollowupPopup(!isUpdateFollowupPopup)}
        >
          <span className="flex justify-between gap-2">
            <DialogHeader>Want to update a Follow Up?</DialogHeader>
          </span>
          <form onSubmit={handleFollowUpOnUpdate}>
            <DialogBody>
              <div className="flex flex-col justify-start gap-4">
                <Select
                  label="Follow-Up By"
                  variant="standard"
                  color="blue"
                  required
                  value={followupBy}
                  valid={followupBy !== 0}
                  onChange={(value) => setFollowupBy(value)}
                  className="my-input-box-1"
                >
                  {userAgentsList.map((agent) => (
                    <Option
                      key={agent?.agentId}
                      value={agent?.agentId}
                      className={`hover:bg-blue-300 focus:bg-blue-300`}
                    >
                      {agent?.agentFullName +
                        " ( " +
                        agent?.agentUsername +
                        " )"}
                    </Option>
                  ))}
                </Select>
                <Input
                  variant="standard"
                  label="Follow-Up remarks"
                  placeholder="Eg. Check if customer has seen your proposal"
                  required
                  value={followupRemarks}
                  onChange={(e) => setFollowupRemarks(e.target.value)}
                />
                <span className="grid grid-cols-2 gap-3">
                  <p className="border-r border-slate-400 text-sm">
                    Schedule Time
                  </p>
                  <input
                    type="datetime-local"
                    required
                    value={selectedFollowupDateTime}
                    onChange={(event) => {
                      setSelectedFollowupDateTime(event.target.value);
                    }}
                    min={addHoursToCurrentDateTime(0.5)}
                    max={addHoursToCurrentDateTime(1080)}
                    className="my-input-style-1"
                  />
                </span>
                {followupCheck !== "" && (
                  <p className="text-xs text-red-500">{followupCheck}</p>
                )}
              </div>
            </DialogBody>
            <DialogFooter>
              <Button
                type="button"
                variant="text"
                color="red"
                onClick={() => setIsUpdateFollowupPopup(false)}
                className="mr-1"
              >
                <span>Cancel</span>
              </Button>
              <Button type="submit" variant="gradient" color="green">
                <span>Confirm</span>
              </Button>
            </DialogFooter>
          </form>
        </Dialog>
      </Card>
    </>
  );
};

export default AssignedFollowup;

const assignedFollowupTableStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.mediumGrey,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {},
  },
};
