import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Input,
  Select,
  Option,
} from "@material-tailwind/react";

import allCountryCodes from "../../data/countryAndCodes.json";
import { useContactsContext } from "../../contexts/ContactsContextProvider";
import { phoneNumberValidationCheck } from "../utils/inputValidations";
import {
  contactNameSanitation,
  phoneNumberSanitation,
} from "../utils/inputSanitation";
const CreateContact = () => {
  const { apiCallCreateContact, isLoading, setIsLoading } =
    useContactsContext();

  const [countryCode, setCountryCode] = useState("91");
  const [phoneNo, setPhoneNo] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");

  const resetOnClick = () => {
    setCountryCode("91");
    setPhoneNo("");
    setFirstName("");
    setLastName("");
    setCompanyName("");
  };

  const createContactOnSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (phoneNumberValidationCheck(phoneNo)) {
      apiCallCreateContact({
        countryCode,
        phoneNo,
        firstName,
        lastName,
        companyName,
      });

      resetOnClick();
    } else {
      toast.warn("Please enter a valid Phone Number!");
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={createContactOnSubmit}>
      <Card>
        <CardHeader
          variant="gradient"
          color="blue"
          className="mb-4 p-5 text-center"
        >
          <Typography variant="h3" color="white">
            Create New Contact
          </Typography>
        </CardHeader>
        <CardBody className="flex flex-col gap-4">
          <Input
            label="First Name"
            variant="standard"
            color="blue"
            labelProps={{
              className: "text-slate-800",
            }}
            value={firstName}
            onChange={(e) =>
              setFirstName(contactNameSanitation(e.target.value))
            }
            className="my-input-box-1"
          />
          <Input
            label="Last Name"
            variant="standard"
            color="blue"
            labelProps={{
              className: "text-slate-800",
            }}
            value={lastName}
            onChange={(e) => setLastName(contactNameSanitation(e.target.value))}
            className="my-input-box-1"
          />

          <Select
            label="Country Code"
            variant="standard"
            color="blue"
            required
            value={countryCode}
            onChange={(e) => setCountryCode(e)}
            className="my-input-box-1"
          >
            {allCountryCodes.map((country) => (
              <Option
                key={country.code}
                value={country.dial_code}
                className={`hover:bg-blue-300 focus:bg-blue-300`}
              >
                {country.code + " +" + country.dial_code}
              </Option>
            ))}
          </Select>
          <Input
            label="Phone Number"
            variant="standard"
            color="blue"
            labelProps={{
              className: "text-slate-800",
            }}
            required
            value={phoneNo}
            onChange={(e) => setPhoneNo(phoneNumberSanitation(e.target.value))}
            className="my-input-box-1"
          />
          <Input
            label="Company Name"
            variant="standard"
            color="blue"
            labelProps={{
              className: "text-slate-800",
            }}
            value={companyName}
            onChange={(e) =>
              setCompanyName(contactNameSanitation(e.target.value))
            }
            className="my-input-box-1"
          />
        </CardBody>

        <button
          disabled={isLoading}
          type="submit"
          className="wbm-cta-btn-1 mx-7 mb-7 disabled:bg-slate-400"
        >
          + Add Contact
        </button>
      </Card>
    </form>
  );
};

export default CreateContact;
