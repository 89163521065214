import React, { useState, useEffect, useRef } from "react";
import { HiUserGroup } from "react-icons/hi";
import { IoClose } from "react-icons/io5";
import { PiNumberSquareSevenFill } from "react-icons/pi";
import { Ri24HoursLine } from "react-icons/ri";
import { MdPermContactCalendar } from "react-icons/md";
import { toast } from "react-toastify";
import {
  ButtonGroup,
  Button,
  Drawer,
  Card,
  CardHeader,
  Typography,
  CardFooter,
  Select,
  Option,
  Dialog,
  DialogBody,
  DialogHeader,
} from "@material-tailwind/react";

import {
  UploadContacts,
  ManageAllContacts,
  ManageContactGroups,
  CreateContact,
  CustomLoader,
  SeoTags,
  CreateContactGroup,
} from "..";
import { useContactsContext } from "../../contexts/ContactsContextProvider";
import { loadMoreContact } from "../../data/messageNotifierServices";

const Contacts = () => {
  const {
    importedContacts,
    isUploaded,
    setResetUploadComponent,
    apiCallUploadContacts,
    apiCallFetchUserContacts,
    apiCallFetchTablePaging,
    contactsInsights,
    apiCallContactsInsights,
    contactGroups,
    apiCallFetchContactGroups,
    isLoading,
    isContactUploading,
  } = useContactsContext();

  const [openContactGroupsDrawer, setOpenContactGroupsDrawer] = useState(false);
  const [openAllContactsDrawer, setOpenAllContactsDrawer] = useState(false);
  const [assignUploadedContactsToGroup, setAssignUploadedContactsToGroup] =
    useState(false);
  const [openGroupCreationDialog, setOpenGroupCreationDialog] = useState(false);
  const [preferredGroup, setPreferredGroup] = useState(0);
  const [groupName, setGroupName] = useState("");
  const [selectedGroup, setSelectedGroup] = useState([]);

  const groupBodyRef = useRef(null);
  const contactBodyRef = useRef(null);
  let subscribe;

  const handleUploadOnClick = async () => {
    if (
      !assignUploadedContactsToGroup ||
      (assignUploadedContactsToGroup && preferredGroup > 0)
    ) {
      apiCallUploadContacts(preferredGroup);
      setAssignUploadedContactsToGroup(false);
      setPreferredGroup(0);
    } else {
      toast.error("Please select a valid contact group to upload");
    }
  };

  const callbackHideContactGroup = () => {
    setOpenContactGroupsDrawer(false);
  };

  const callbackHideAllContacts = () => {
    subscribe?.unsubscribe();
    setOpenAllContactsDrawer(false);
  };

  useEffect(() => {
    apiCallFetchUserContacts(1, "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploaded]);

  useEffect(() => {
    apiCallContactsInsights();
    apiCallFetchContactGroups();
    if (openAllContactsDrawer) {
      apiCallFetchUserContacts(1, "");
      apiCallFetchTablePaging("");
      contactBodyRef.current.focus();
    } else {
      groupBodyRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    subscribe = loadMoreContact?.subscribe((data) => {
      setOpenContactGroupsDrawer(false);
      setOpenAllContactsDrawer(data?.drawerStatus);
      setGroupName(data?.groupName);
      setSelectedGroup(data?.contactInGroup);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openAllContactsDrawer]);

  return (
    <main className="space-y-5">
      <SeoTags title="Contacts ‣ WaBM" />

      {(isLoading || isContactUploading) && !openAllContactsDrawer && (
        <CustomLoader />
      )}

      {/* All Contacts Drawer | reverse cond. to overcome overlapping of drawers at same position */}
      {!openContactGroupsDrawer && (
        <Drawer
          ref={contactBodyRef}
          placement="bottom"
          open={openAllContactsDrawer}
          onClose={() => setOpenAllContactsDrawer(false)}
          size="91vh"
          className="rounded-t-xl z-[9996]"
        >
          <ManageAllContacts
            callbackHideAllContacts={callbackHideAllContacts}
            drawerStatus={openAllContactsDrawer}
            groupName={groupName}
            contactInGroup={selectedGroup}
          />
        </Drawer>
      )}

      {/* Contact Groups Drawer */}
      {!openAllContactsDrawer && (
        <Drawer
          ref={groupBodyRef}
          placement="bottom"
          open={openContactGroupsDrawer}
          onClose={() => setOpenContactGroupsDrawer(false)}
          size="91vh"
          className="p-5 rounded-t-xl z-[9996]"
        >
          <ManageContactGroups
            callbackHideContactGroup={callbackHideContactGroup}
            drawerStatus={openContactGroupsDrawer}
          />
        </Drawer>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-5 gap-5">
        <div className="col-span-3 space-y-5">
          <div className="space-y-2">
            <h1 className="text-2xl">Manage Contacts</h1>
            <p>
              Upload new contacts or manage the existing ones. Maybe manage your
              contact groups?
            </p>
          </div>

          <div className="grid grid-cols-2 gap-2">
            <div className="wbm-card-1 bg-blue-400 space-y-2">
              <MdPermContactCalendar size={30} />
              <p className="">Total Contacts</p>
              <p className="text-3xl">{contactsInsights?.totalContacts}</p>
            </div>
            <div className="wbm-card-1 bg-green-400 space-y-2">
              <HiUserGroup size={30} />
              <p className="">Total Saved Groups</p>
              <p className="text-3xl">{contactsInsights?.totalGroups}</p>
            </div>
            <div className="wbm-card-1 bg-amber-400 space-y-2">
              <Ri24HoursLine size={30} />
              <p className="">Contacted in Last 24 hrs</p>
              <p className="text-3xl">
                {contactsInsights?.totalLast24Contacted}
              </p>
            </div>
            <div className="wbm-card-1 bg-indigo-400 space-y-2">
              <PiNumberSquareSevenFill size={30} />
              <p className="">Contacted in Last 7 days</p>
              <p className="text-3xl">
                {contactsInsights?.totalContactedLast7Days}
              </p>
            </div>
          </div>

          <div className="space-y-3">
            <p>Click one of the following buttons view the required data.</p>
            <ButtonGroup variant="gradient" color="blue">
              <Button
                type="button"
                onClick={() => {
                  setSelectedGroup([]);
                  setGroupName("");
                  apiCallFetchContactGroups();
                  setOpenAllContactsDrawer(true);
                }}
                className="w-48"
              >
                All Contacts
              </Button>
              <Button
                type="button"
                onClick={() => {
                  setOpenContactGroupsDrawer(true);
                  apiCallFetchContactGroups();
                }}
                className="w-48"
              >
                Saved Groups
              </Button>
            </ButtonGroup>
          </div>
        </div>

        <div className="col-span-2 space-y-10">
          <UploadContacts />
          <CreateContact />
        </div>
      </div>

      {isUploaded && (
        <Dialog
          placement="bottom"
          open={isUploaded}
          className="rounded-t-xl z-[999]"
        >
          {importedContacts !== null && importedContacts !== undefined && (
            <Card className="h-full w-full">
              <CardHeader
                floated={false}
                shadow={false}
                className="rounded-none"
              >
                <Typography variant="h5" color="blue-gray">
                  Data Preview
                </Typography>
                <div className="space-y-2">
                  <p className="mt-1">
                    These are contact details from your attached Excel Sheet.
                  </p>
                  <div className="flex gap-2">
                    <Typography className="text-lg font-bold">
                      Total Contacts in the Excel Sheet :
                    </Typography>
                    <Typography color="green" className="text-lg font-bold">
                      {importedContacts?.length}
                    </Typography>
                  </div>
                </div>
              </CardHeader>
              <CardFooter className="space-y-3">
                <div className="wbm-card-3 space-x-2 space-y-2">
                  {assignUploadedContactsToGroup && (
                    <Select
                      variant="outlined"
                      label="Add to Contact Group (optional)"
                      color="blue"
                      value={preferredGroup}
                      onChange={(e) => setPreferredGroup(e)}
                    >
                      {contactGroups?.map((group) => (
                        <Option
                          key={group?.groupId}
                          value={group?.groupId}
                          className="hover:bg-blue-300 focus:bg-blue-300"
                        >
                          {group?.groupName} (
                          {group?.numberOfContacts ? group.numberOfContacts : 0}{" "}
                          contacts)
                        </Option>
                      ))}
                    </Select>
                  )}

                  {assignUploadedContactsToGroup && (
                    <Button
                      type="button"
                      variant="gradient"
                      color="blue"
                      onClick={() => setOpenGroupCreationDialog(true)}
                      className="whitespace-nowrap w-full md:w-52"
                    >
                      Create New Group
                    </Button>
                  )}

                  {assignUploadedContactsToGroup ? (
                    <Button
                      type="button"
                      variant="gradient"
                      color="red"
                      onClick={() => {
                        setAssignUploadedContactsToGroup(false);
                        setPreferredGroup(0);
                      }}
                      className="whitespace-nowrap"
                    >
                      Cancel
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      variant="gradient"
                      color="blue"
                      onClick={() => setAssignUploadedContactsToGroup(true)}
                      className="whitespace-nowrap"
                    >
                      Assign Contacts to Group
                    </Button>
                  )}
                </div>

                <div>
                  <Button
                    type="button"
                    variant="gradient"
                    color="green"
                    onClick={handleUploadOnClick}
                  >
                    Upload
                  </Button>
                </div>

                <button
                  onClick={() => setResetUploadComponent(true)}
                  className="my-button-style-cancel absolute top-5 right-5"
                >
                  <IoClose size={18} />
                </button>
              </CardFooter>
            </Card>
          )}
        </Dialog>
      )}

      <Dialog
        open={openGroupCreationDialog}
        handler={() => setOpenGroupCreationDialog(!openGroupCreationDialog)}
      >
        <span className="flex justify-between gap-3">
          <DialogHeader>Add Contact Group</DialogHeader>
        </span>
        <DialogBody>
          <CreateContactGroup
            callbackHideContactGroup={() => {
              apiCallFetchContactGroups();
              setOpenGroupCreationDialog(!openGroupCreationDialog);
            }}
          />
        </DialogBody>
      </Dialog>
    </main>
  );
};

export default Contacts;
