import { React, useState } from "react";
import DataTable from "react-data-table-component";
import { Button } from "@material-tailwind/react";
import { AiFillSchedule } from "react-icons/ai";
import { MdDescription } from "react-icons/md";
import * as XLSX from "xlsx";

import { colorsConfig } from "../../config/colorsConfig";
import { useContactsContext } from "../../contexts/ContactsContextProvider";
import { Loader } from "..";
import { isoTimestampToLocal } from "../utils/timeStampConversion";

const ViewTrackingContactsTable = ({ scheduleStatus, jobId, selectedJob }) => {
  const {
    scheduledCampaignsTrackingData,
    apiResendCampaign,
    isLoading,
    isProcessing,
  } = useContactsContext();
  const [isVisibleSendButton, setIsVisibleSendButton] = useState(false);
  const [selectedContact, setSelectedContact] = useState([]);

  let selectedFailedContact = [];

  const scheduleTrackingTableColumns = [
    {
      name: "Contact Number",
      selector: (row) => row?.ContactNumber,
    },
    {
      name: "Contact Name",
      selector: (row) => row?.contactName,
      grow: 2,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      center: true,
    },
    {
      name: "Company",
      selector: (row) => row?.companyName,
    },
    {
      name: "City",
      selector: (row) => row?.city,
    },
    {
      name: "Send Status",
      selector: (row) => row?.MessageStatus,
      center: true,
    },
    {
      name: "Message Status",
      selector: (row) => (
        <p
          className={
            row?.MessageStatusFromWhatsapp === "read"
              ? "text-green-500"
              : row?.MessageStatusFromWhatsapp === "delivered"
              ? "text-blue-500"
              : row?.MessageStatusFromWhatsapp === "failed"
              ? "text-red-500"
              : "text-slate-700"
          }
        >
          {row?.MessageStatusFromWhatsapp}
        </p>
      ),
      center: true,
    },
  ];

  const rowDisabledCriteria = (row) => {
    if (row.MessageStatus === "Success") {
      return true;
    } else {
      return false;
    }
  };

  const handleChange = ({ selectedRows }) => {
    selectedFailedContact = [];
    selectedRows?.map((x) => selectedFailedContact.push(x.ContactNumber));
    if (selectedFailedContact.length > 0) {
      setSelectedContact(selectedFailedContact);
      setIsVisibleSendButton(true);
    } else {
      setSelectedContact([]);
      setIsVisibleSendButton(false);
    }
  };

  const handleResend = async (e) => {
    e.preventDefault();
    await apiResendCampaign({
      scheduleId: jobId,
      selectedContacts: selectedContact,
      scheduleStatus: scheduleStatus,
    });
  };

  const downloadAllContactsOnClick = async () => {
    let contactArray = [];
    // eslint-disable-next-line array-callback-return
    scheduledCampaignsTrackingData?.map((contact) => {
      contactArray.push({
        "Contact Number": contact?.ContactNumber,
        "Contact Name": contact?.contactName,
        Email: contact?.email,
        Company: contact?.companyName,
        City: contact?.city,
        "Send Status": contact?.MessageStatus,
        "Message Status": contact?.MessageStatusFromWhatsapp,
      });
    });

    const dataForForDownload = contactArray;
    const worksheet = XLSX.utils.json_to_sheet(dataForForDownload);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(
      workbook,
      `Campaign Data - ${selectedJob?.scheduleName} - ${isoTimestampToLocal(
        selectedJob?.scheduleDateTime
      )}.xlsx`
    );
  };

  return (
    <main className="p-5 space-y-2">
      <h3 className="wabm-font-style-2 text-white">Message Tracking Status</h3>
      <span className="flex items-center gap-2 text-white">
        <AiFillSchedule size={24} />
        <h4 className="text-xl font-bold">{selectedJob?.scheduleName}</h4>
      </span>
      {selectedJob?.scheduleDescription && (
        <span className="flex items-center gap-2 text-white">
          <MdDescription size={16} />
          <p className="text-white">{selectedJob?.scheduleDescription}</p>
        </span>
      )}
      <div className="flex flex-wrap justify-between items-center gap-3 text-white text-sm">
        <p>
          Created : {isoTimestampToLocal(selectedJob?.scheduleCreationDate)}
        </p>
        <p>Scheduled : {isoTimestampToLocal(selectedJob?.scheduleDateTime)}</p>
        <p>Status : {selectedJob?.scheduleStatus}</p>
        <Button
          color="green"
          size="sm"
          onClick={() => downloadAllContactsOnClick()}
          className="w-fit px-5 whitespace-nowrap"
        >
          Download Report
        </Button>
      </div>

      {isVisibleSendButton && (
        <Button
          disabled={isProcessing}
          onClick={handleResend}
          variant="gradient"
          color="green"
        >
          {isProcessing ? <span>Sending...</span> : <span>Send</span>}
        </Button>
      )}

      <div className="w-full max-h-[90vh] pt-3 rounded-md overflow-auto">
        {isLoading ? (
          <Loader />
        ) : scheduledCampaignsTrackingData.length > 0 ? (
          <DataTable
            fixedHeader
            selectableRows
            selectableRowDisabled={rowDisabledCriteria}
            onSelectedRowsChange={handleChange}
            columns={scheduleTrackingTableColumns}
            data={scheduledCampaignsTrackingData}
            fixedHeaderScrollHeight="75vh"
            customStyles={scheduleTableStyles}
          />
        ) : (
          <div className="space-y-3">
            <p>No Tracking data found.</p>
          </div>
        )}
      </div>
    </main>
  );
};

export default ViewTrackingContactsTable;

const scheduleTableStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.wabmBlue,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {},
  },
};
