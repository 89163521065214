export const inputNameSanitation = (inputName) => {
  const inputRegex = /[^\p{L}\s0-9]/gu;
  return inputName.replace(inputRegex, "");
};

export const contactNameSanitation = (inputName) => {
  const inputRegex = /[^\p{L}\s0-9\-.,()[\]'/:&@*]/gu;
  return inputName.replace(inputRegex, "");
};

export const phoneNumberSanitation = (phoneNumber) => {
  const inputRegex = /[^0-9]/gi;
  return phoneNumber.replace(inputRegex, "");
};
export const countryCodeSanitation = (countryCode) => {
  const inputRegex = /[^0-9]{1,4}/g;
  return countryCode.replace(inputRegex, "");
};

export const inputMessageSanitation = (message) => {
  const inputRegex = /[<>{}\\;`]+/gi;
  return message.replace(inputRegex, "");
};

export const inputUsernameSanitation = (input) => {
  return input
    .replace(/[^a-zA-Z0-9_\s]/g, "")
    .replace(/[\s_]+/g, "_")
    .trim()
    .toLowerCase();
};

export const templateHeaderTextSanitation = (message) => {
  const inputRegex = /[~`^*^]/g;
  return message
    .replace(inputRegex, "")
    .replace(
      /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{FE00}-\u{FE0F}\u{1F1E6}-\u{1F1FF}\u{1F018}-\u{1F270}\u{238C}-\u{2454}\u{20D0}-\u{20FF}]/gu,
      ""
    );
};

export const templateButtonSanitation = (message) => {
  const inputRegex = /[~_={}[\]<>]+/g;
  return message.replace(inputRegex, "");
};

export const couponCodeSanitation = (input) => {
  return input
    .replace(/[^a-zA-Z0-9]+$/g, "")
    .trim()
    .toUpperCase();
};
