import React, { useState, useEffect } from "react";
import { v4 as uuid } from "uuid";
import {
  Drawer,
  Button,
  Typography,
  CardBody,
  Select,
  Option,
  Input,
} from "@material-tailwind/react";
import { IoClose } from "react-icons/io5";
import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import { MdDeleteOutline } from "react-icons/md";
import { RiDeleteBin7Fill } from "react-icons/ri";

import {
  uploadMediaOnAWS,
  EnumFileUploadStatus,
} from "../../helpers/AWSService.js";
import { useConversationContext } from "../../contexts/ConversationContextProvider";
import { useUserContext } from "../../contexts/UserContextProvider";
import { Loader, SeoTags } from "..";

const ManageWelcomeMessages = () => {
  const {
    saveWelcomeMessage,
    getWelcomeMessages,
    deleteWelcomeMessage,
    changeWelcomeMessagesOrder,
  } = useConversationContext();

  const { isLoading } = useUserContext();

  const blankMessage = {
    messageType: "text",
    bodyText: "",
    mediaValue: null,
  };

  const [toCreateWelcomeMessages, setToCreateWelcomeMessages] = useState(false);
  const [welcomeMessageList, setWelcomeMessageList] = useState([]);
  const [isReload, setIsReload] = useState(false);
  const [listOfWelcomeMessages, setListOfWelcomeMessages] = useState([
    blankMessage,
  ]);
  const [toReorderMessages, setToReorderMessages] = useState(false);
  const [draggingItem, setDraggingItem] = useState(null);
  const [welcomeMessageBodyText, setWelcomeMessageBodyText] = useState("");

  const welcomeMessagesTableHeads = ["Message Type", "Message Text", "Action"];

  const checkDuplicates = (array) => {
    const seen = new Set();
    const duplicates = [];

    array.forEach((item) => {
      if (seen.has(item.messageOrder)) {
        duplicates.push(item);
      } else {
        seen.add(item.messageOrder);
      }
    });

    return duplicates;
  };

  const handleDragStart = (e, index) => {
    setDraggingItem(index);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    setToReorderMessages(true);

    const updatedItems = [...welcomeMessageList];
    const draggedItem = updatedItems[draggingItem];

    // Remove the item from its original position
    updatedItems.splice(draggingItem, 1);

    // Insert the item at the new index
    updatedItems.splice(index, 0, draggedItem);

    setDraggingItem(null);
    setWelcomeMessageList(updatedItems);
  };

  const handleDeleteOnClick = async (messageId) => {
    deleteWelcomeMessage(messageId);
    await loadWelcomeMessages();
  };

  const resetStates = () => {
    setToCreateWelcomeMessages(false);
    setListOfWelcomeMessages([blankMessage]);
    setIsReload(false);
    setToReorderMessages(false);
    setDraggingItem(false);
  };

  const uploadMedia = async (uploadedFile) => {
    try {
      const uniqueId = uuid();
      const uniqueFileName =
        uniqueId + "." + uploadedFile.name.split(".").pop().toLowerCase();
      const uploadParams = {
        fileName: uniqueFileName,
        mediaFile: uploadedFile,
        fileType: uploadedFile.type,
      };

      const response = await uploadMediaOnAWS(uploadParams);
      if (response === EnumFileUploadStatus.FileUploadSuccess) {
        return uniqueFileName;
      } else {
        return "";
      }
    } catch (err) {
      console.log("welcome messages uploadMedia err", err);
      return "";
    }
  };

  const changeOrderOnClick = async () => {
    const duplicates = checkDuplicates(welcomeMessageList);

    if (duplicates?.length > 0) {
      toast.error("Duplicate message order found");
    } else {
      welcomeMessageList.forEach((element, idx) => {
        element.messageOrder = idx + 1;
      });

      await changeWelcomeMessagesOrder(welcomeMessageList);
      await loadWelcomeMessages();
      setToReorderMessages(false);
    }
  };

  const handleWelcomeMessageOnSubmit = async (e) => {
    e.preventDefault();

    let hasBlankBodyText = false;
    let welcomeMessages = [];
    for (let i = 0; i < listOfWelcomeMessages.length; i++) {
      const message = listOfWelcomeMessages[i];

      if (message.messageType !== "text" && message.mediaValue) {
        const file = message.mediaValue;
        const fileName = await uploadMedia(file);
        welcomeMessages.push({
          message_type: message.messageType,
          bodyText: fileName,
        });
      } else {
        if (!message.bodyText || message.bodyText.trim() === "") {
          toast.error(`Message body text is required`);
          hasBlankBodyText = true;
          return;
        } else {
          welcomeMessages.push({
            message_type: message.messageType,
            bodyText: message.bodyText,
          });
        }
      }
      if (!hasBlankBodyText && i === listOfWelcomeMessages.length - 1) {
        saveWelcomeMessage(welcomeMessages);
        setToCreateWelcomeMessages(false);
        await loadWelcomeMessages();
      }
    }
  };

  const handleSelectOnChange = (index, newMessageType) => {
    const updatedMessages = listOfWelcomeMessages.map((item, i) =>
      i === index
        ? {
            ...item,
            messageType: newMessageType,
            bodyText: "",
            mediaValue: null,
          }
        : item
    );
    setListOfWelcomeMessages(updatedMessages);
  };

  const handleInputOnChange = (index, value, type) => {
    const updatedMessages = listOfWelcomeMessages.map((item, i) =>
      i === index ? { ...item, [type]: value } : item
    );
    setWelcomeMessageBodyText(value);
    setListOfWelcomeMessages(updatedMessages);
  };

  const loadWelcomeMessages = async () => {
    const result = await getWelcomeMessages();
    setWelcomeMessageList(result);
  };

  const handleScrollOnScroll = (e) => {
    // Prevent scrolling from bubbling up to parent elements
    e.stopPropagation();
  };

  useEffect(() => {
    setIsReload(false);
    loadWelcomeMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReload]);

  return (
    <>
      <SeoTags title="Welcome Messages ‣ WaBM" />

      <main className="w-full mt-5 mb-10 lg:my-1 space-y-5">
        <div className="w-full space-y-2">
          <h1 className="text-2xl">Manage Welcome Messages</h1>
          <h3>Create, Delete and Manage welcome messages.</h3>
        </div>
        <Button
          type="button"
          variant="gradient"
          color="blue"
          onClick={() => {
            handleInputOnChange(0, "", "bodyText");
            setWelcomeMessageBodyText("");
            setToCreateWelcomeMessages(true);
          }}
        >
          Create Welcome Message
        </Button>
        <div className="wbm-card-3 space-y-3">
          <div className="min-h-[18px] flex flex-wrap justify-between items-center gap-5">
            <h2 className="font-Montserrat text-lg">Welcome Messages</h2>
            {toReorderMessages && (
              <div className="flex items-center gap-3">
                <Button
                  type="button"
                  variant="gradient"
                  color="green"
                  size="sm"
                  onClick={() => changeOrderOnClick()}
                >
                  Update Message Order
                </Button>
                <Button
                  type="button"
                  variant="gradient"
                  color="red"
                  size="sm"
                  onClick={() => {
                    resetStates();
                    loadWelcomeMessages();
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
          <div className="rounded-md overflow-auto">
            {isLoading ? (
              <Loader />
            ) : (
              <CardBody className="max-h-[70vh] p-0 overflow-y-scroll">
                {welcomeMessageList?.length > 0 ? (
                  <table className="w-full min-w-max table-auto text-left">
                    <thead className="bg-slate-200 sticky -top-1 z-10">
                      <tr className="text-center">
                        {welcomeMessagesTableHeads.map((head) => (
                          <th
                            key={head}
                            className="p-4 border-r border-white bg-blue-gray-50/50"
                          >
                            <Typography
                              variant="small"
                              className="font-normal leading-none"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {welcomeMessageList?.map((item, index) => {
                        const isLast = index === welcomeMessageList?.length - 1;
                        const classes = isLast
                          ? "px-2 py-2"
                          : "px-2 py-2 border-b border-blue-gray-50";

                        return (
                          <tr
                            key={index}
                            draggable
                            onDragStart={(e) => handleDragStart(e, index)}
                            onDragOver={(e) => e.preventDefault()}
                            onDrop={(e) => handleDrop(e, index)}
                            className="my-smooth-transition-1 hover:bg-blue-200 text-center hover:cursor-move"
                          >
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {item?.message_type}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal"
                              >
                                {item?.message_body_t}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Popup
                                trigger={
                                  <button
                                    type="button"
                                    className="my-button-style-cancel"
                                  >
                                    <RiDeleteBin7Fill size="16px" />
                                  </button>
                                }
                                position="left bottom"
                                className="hidden"
                              >
                                <div className="my-popup-style-1">
                                  <p>
                                    Are you sure you want to delete this welcome
                                    message?
                                  </p>
                                  <button
                                    type="button"
                                    onClick={() =>
                                      handleDeleteOnClick(item?.Id)
                                    }
                                    className="my-button-style-cancel mt-2 flex items-center gap-1 text-sm"
                                  >
                                    <MdDeleteOutline size="0.8rem" />
                                    Delete this message
                                  </button>
                                </div>
                              </Popup>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                ) : (
                  <p className="pt-3 pl-4 text-sm italic">
                    No Welcome messages found.
                  </p>
                )}
              </CardBody>
            )}
          </div>
        </div>
      </main>

      <Drawer
        placement="right"
        onScroll={handleScrollOnScroll}
        open={toCreateWelcomeMessages}
        onClose={() => setToCreateWelcomeMessages(false)}
        size="500px"
        className="w-full p-4 z-[9996] overflow-y-scroll"
      >
        <form onSubmit={handleWelcomeMessageOnSubmit} className="space-y-5">
          <div className="flex justify-center flex-row">
            <Typography
              variant="h4"
              color="blue"
              className="text-center items-center"
            >
              Create Welcome Message
            </Typography>
            <button
              type="button"
              onClick={() => resetStates()}
              className="my-button-style-cancel absolute top-5 right-5 items-end"
            >
              <IoClose size={18} />
            </button>
          </div>

          {listOfWelcomeMessages?.map((item, index) => (
            <div
              key={index}
              className="w-full mt-3 p-3 space-y-5 border-x border-slate-500 rounded"
            >
              <Select
                label="Message Type"
                variant="standard"
                color="blue"
                required
                value={item?.messageType}
                onChange={(e) => handleSelectOnChange(index, e)}
                className="my-input-box-1"
              >
                <Option
                  key="text"
                  value="text"
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                >
                  Text
                </Option>
                <Option
                  key="image"
                  value="image"
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                >
                  Image
                </Option>
                <Option
                  key="video"
                  value="video"
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                >
                  Video
                </Option>
                <Option
                  key="audio"
                  value="audio"
                  className={`hover:bg-blue-300 focus:bg-blue-300`}
                >
                  Audio
                </Option>
              </Select>
              {item?.messageType !== "text" ? (
                <Input
                  type="file"
                  label="Auto Reply Media"
                  variant="standard"
                  color="blue"
                  labelProps={{
                    className: "text-slate-800",
                  }}
                  value={welcomeMessageBodyText}
                  autoComplete="off"
                  onChange={(e) =>
                    handleInputOnChange(index, e.target.files[0], "mediaValue")
                  }
                />
              ) : (
                <Input
                  label="Auto Reply Message"
                  variant="standard"
                  color="blue"
                  labelProps={{
                    className: "text-slate-800",
                  }}
                  autoComplete="off"
                  value={welcomeMessageBodyText}
                  onChange={(e) =>
                    handleInputOnChange(index, e.target.value, "bodyText")
                  }
                  className="my-input-box-1"
                />
              )}
            </div>
          ))}
          <Button variant="gradient" color="blue" type="submit" fullWidth>
            Create Welcome Message
          </Button>
        </form>
      </Drawer>
    </>
  );
};
export default ManageWelcomeMessages;
