import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import io from "socket.io-client";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactFlowProvider } from "@xyflow/react";

import {
  Faqs,
  Contact,
  Loginpage,
  Signup,
  Dashboard,
  PageNotFound,
  AppSetup,
  PasswordReset,
  PrivacyPolicy,
  TermsAndConditions,
  CancellationRefundPolicy,
  PaymentFailed,
  PaymentResponse,
} from "./pages";
import { WhatsappContextProvider } from "./contexts/WhatsappContextProvider";
import { PaymentsContextProvider } from "./contexts/PaymentsContextProvider";
import { CatalogContextProvider } from "./contexts/CatalogContextProvider";
import { useUserContext } from "./contexts/UserContextProvider";
import { useConversationContext } from "./contexts/ConversationContextProvider";
import { messageService } from "./data/messageNotifierServices";
import { ContextMenuProvider } from "./contexts/ContextMenuContextProvider";

const App = () => {
  const helmetContext = {};
  const { isLoggedIn, currentUserInfo } = useUserContext();
  const {
    socketConnection,
    setSocketConnection,
    messageNotificationCount,
    setMessageNotificationCount,
  } = useConversationContext();

  const sendNotification = (msg) => {
    // Only notify if message is received and not sent
    if (msg?.messageData?.hasOwnProperty("from")) {
      let notificationBody = "";

      if (msg?.messageData?.hasOwnProperty("text")) {
        notificationBody = msg?.messageData?.text?.body;
      } else if (msg?.messageData?.hasOwnProperty("image")) {
        notificationBody = "Image";
      } else if (msg?.messageData?.hasOwnProperty("document")) {
        notificationBody = "Document";
      } else if (msg?.messageData?.hasOwnProperty("audio")) {
        notificationBody = "Audio";
      } else if (msg?.messageData?.hasOwnProperty("video")) {
        notificationBody = "Video";
      } else if (msg?.messageData?.hasOwnProperty("contacts")) {
        notificationBody = "Contact";
      } else if (msg?.messageData?.hasOwnProperty("location")) {
        notificationBody = "Location";
      } else if (msg?.messageData?.hasOwnProperty("template")) {
        notificationBody = "Template Message";
      } else if (msg?.messageData?.hasOwnProperty("interactive")) {
        notificationBody = "Interactive Message";
      } else {
        notificationBody = "Unknown Message Type";
      }

      // console.log("App.js msg", msg);

      let notificationTitle = "";
      if (msg?.profile?.name) {
        notificationTitle = msg?.profile?.name;
      } else if (msg?.messageData?.from) {
        notificationTitle = `+${msg?.messageData?.from}`;
      } else {
        notificationTitle = "Message on WaBM";
      }
      if (window.location.pathname !== "/dashboard/chats") {
        setMessageNotificationCount(messageNotificationCount + 1);
      }

      if (window.location.pathname !== "/dashboard/chats") {
        if (!("Notification" in window)) {
          toast?.warning(
            "Please turn on desktop notifications to stay updated!"
          );
        } else if (Notification.permission === "granted") {
          new Notification(notificationTitle, {
            body: notificationBody,
          });
        } else if (Notification.permission === "denied") {
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              new Notification(notificationTitle, {
                body: notificationBody,
              });
            }
          });
        }
      }
    }
  };

  const receiveAppNotification = (msg) => {
    if (Notification.permission === "granted") {
      new Notification(msg.title, {
        body: msg.body,
      });
    } else if (Notification.permission === "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          new Notification(msg.title, {
            body: msg.body,
          });
        }
      });
    }
  };

  useEffect(() => {
    // console.log("currentUserInfo", currentUserInfo);

    if (currentUserInfo?.socketServerUrl && !socketConnection) {
      const socket = io.connect(currentUserInfo?.socketServerUrl);
      setSocketConnection(socket);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserInfo]);

  useEffect(() => {
    if (socketConnection) {
      setInterval(() => {
        if (socketConnection.connected) {
          messageService.sendSocketConnectionNotifier(true);
        } else {
          messageService.sendSocketConnectionNotifier(false);
        }
      }, 5000);
      socketConnection.on(currentUserInfo?.wabPhoneId, sendNotification);
      socketConnection.on(currentUserInfo?.emailId, receiveAppNotification);
      socketConnection.on("connect", function () {
        messageService.sendSocketConnectionNotifier(true);
      });
      socketConnection.on("disconnect", function () {
        messageService.sendSocketConnectionNotifier(false);
      });
      socketConnection.on("reconnect", function () {
        messageService.sendSocketConnectionNotifier(false);
      });
      return () => {
        socketConnection.off(currentUserInfo?.wabPhoneId, sendNotification);
        socketConnection.off(currentUserInfo?.emailId, receiveAppNotification);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketConnection]);

  return (
    <HelmetProvider context={helmetContext}>
      <ContextMenuProvider>
        <WhatsappContextProvider>
          <PaymentsContextProvider>
            <CatalogContextProvider>
              <ReactFlowProvider>
                <ToastContainer position="bottom-left text-center z-[9999]" />
                <Routes>
                  <Route path="/" element={<Navigate replace to="/login" />} />
                  <Route path="/login" element={<Loginpage />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/faq" element={<Faqs />} />

                  <Route
                    path="/dashboard/*"
                    element={
                      isLoggedIn &&
                      currentUserInfo?.mandatoryUserDetailsExist &&
                      currentUserInfo?.mandatoryWabApiCreds ? (
                        <Dashboard />
                      ) : (
                        <Navigate replace to="/setup" />
                      )
                    }
                  />
                  <Route
                    path="/setup"
                    element={
                      isLoggedIn ? <AppSetup /> : <Navigate replace to="/" />
                    }
                  />
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/password-reset" element={<PasswordReset />} />

                  <Route path="/payment-failed" element={<PaymentFailed />} />
                  <Route
                    path="/payment-response/:transactionId"
                    element={<PaymentResponse />}
                  />

                  <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  <Route
                    path="/terms-and-conditions"
                    element={<TermsAndConditions />}
                  />
                  <Route
                    path="/cancellation-refund-policy"
                    element={<CancellationRefundPolicy />}
                  />

                  <Route path="*" element={<PageNotFound />} />
                </Routes>
              </ReactFlowProvider>
            </CatalogContextProvider>
          </PaymentsContextProvider>
        </WhatsappContextProvider>
      </ContextMenuProvider>
    </HelmetProvider>
  );
};

export default App;
