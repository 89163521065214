import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
} from "@material-tailwind/react";
import { toast } from "react-toastify";

import { usePaymentsContext } from "../../contexts/PaymentsContextProvider";
import { paymentConfig } from "../../config/paymentConfig.js";
import { colorsConfig } from "../../config/colorsConfig.js";

const RechargeModule = () => {
  const { rechargeViaPhonePe, getCoinsDetails, getCoinsRechargeTier } =
    usePaymentsContext();

  const [rechargeAmount, setRechargeAmount] = useState(null);
  const [gstAmount, setGstAmount] = useState(0);
  const [totalCoinsGained, setTotalCoinsGained] = useState(0);
  const [incomingConvCoinsNow, setIncomingConvCoinsNow] = useState(0);
  const [outGoingConvCoinsNow, setOutGoingConvCoinsNow] = useState(0);
  const [balanceCoins, setBalanceCoins] = useState(0);
  const [coinsRechargeTier, setCoinsRechargeTier] = useState([]);
  const [isOpenRechargeDialog, setIsOpenRechargeDialog] = useState(false);
  const [currentConvRate, setCurrentConvRate] = useState();

  const rechargeViaPhonePeOnClick = async () => {
    const res = await rechargeViaPhonePe(
      parseFloat(rechargeAmount) + parseFloat(gstAmount),
      "recharge"
    );

    if (res?.Error) {
      toast.error(res.Error);
    } else if (res?.paymentLink) {
      window.open(res.paymentLink, "_blank");
      setRechargeAmount(0);
    } else {
      toast.error(
        "Failed to process the payment request. Please contact WaBM support team."
      );
    }
  };

  const calculateRechargeAmount = async () => {
    if (rechargeAmount < parseFloat(paymentConfig.minRechargeAmount)) {
      toast.error(
        `Minimum recharge amount is ${parseFloat(
          paymentConfig.minRechargeAmount
        )}`
      );
    } else {
      const gstAmount = (
        (rechargeAmount * parseFloat(paymentConfig.rechargeGST)) /
        100
      ).toFixed(2);
      setGstAmount(gstAmount);
      const totalCoinsGained = (rechargeAmount * 100).toFixed(0);
      setTotalCoinsGained(totalCoinsGained);

      const filterData = coinsRechargeTier.find(
        (x) =>
          parseFloat(x.min_recharge_amount) <= parseFloat(rechargeAmount) &&
          parseFloat(x.max_recharge_amount) >= parseFloat(rechargeAmount)
      );

      setCurrentConvRate(filterData);

      const incomingCoinsGained =
        parseFloat(filterData?.incomming_conv_rate) > 0
          ? (totalCoinsGained / filterData?.incomming_conv_rate)?.toFixed(0)
          : 0;
      const outgoingCoinsGained =
        parseFloat(filterData?.conv_rate) > 0
          ? (totalCoinsGained / filterData?.conv_rate)?.toFixed(0)
          : 0;
      setIncomingConvCoinsNow(incomingCoinsGained);
      setOutGoingConvCoinsNow(outgoingCoinsGained);
      setIsOpenRechargeDialog(true);
    }
  };

  const rechargeTierTableColumn = [
    {
      name: "Min Recharge Amount",
      selector: (row) => row?.min_recharge_amount,
      sortable: true,
      width: "12rem",
    },
    {
      name: "Max Recharge Amount",
      selector: (row) => row?.max_recharge_amount,
      sortable: true,
      width: "12rem",
    },
    {
      name: "Outgoing Conversation Rate",
      selector: (row) => row?.conv_rate,
      sortable: true,
      width: "13rem",
    },
    {
      name: "Incoming Conversation Rate",
      selector: (row) => row?.incomming_conv_rate,
      sortable: true,
      width: "13rem",
    },
    {
      name: "Description",
      selector: (row) => row?.description,
      sortable: true,
      grow: 3,
    },
  ];

  const fetchCoinsDetails = async () => {
    const coinsDetails = await getCoinsDetails();
    if (coinsDetails && coinsDetails.result) {
      setBalanceCoins(coinsDetails.result[0]?.balanceCoins);
    }
  };

  const fetchCoinsRechargeTier = async () => {
    const coinsRechargeTier = await getCoinsRechargeTier();
    if (coinsRechargeTier && coinsRechargeTier.result) {
      setCoinsRechargeTier(coinsRechargeTier.result);
    }
  };

  useEffect(() => {
    fetchCoinsDetails();
    fetchCoinsRechargeTier();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <main className="w-full mt-5 mb-10 lg:my-1 space-y-5">
        <div className="w-full space-y-2">
          <h1 className="text-2xl">Manage Recharge</h1>
          <h3>These are details about all your current and past recharge.</h3>
        </div>
        <div className="wbm-card-2 h-fit flex flex-col gap-3 mt-8">
          <span className="grid grid-cols-6  gap-3">
            <h3 className="border-r-[0.5px] border-slate-400 text-blue-950 font-bold">
              Balance Coins
            </h3>
            <p className="text-green-700 font-bold">{balanceCoins}</p>
          </span>
          <div className="grid grid-cols-4 sm:grid-cols-2 gap-2 w-full">
            <input
              value={rechargeAmount}
              onChange={(e) => setRechargeAmount(e.target.value)}
              className="w-full border-2 border-b-slate-700 p-2"
              placeholder="Enter recharge amount"
            ></input>
            <button
              onClick={calculateRechargeAmount}
              className="my-button-style-1 w-44 self-end"
            >
              Calculate Amount
            </button>
          </div>
        </div>
      </main>
      <Dialog
        open={isOpenRechargeDialog}
        handler={() => setIsOpenRechargeDialog(!isOpenRechargeDialog)}
        className="rounded-t-xl z-[999]"
      >
        <DialogHeader>
          <div>Recharge Details</div>
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-col justify-start gap-2">
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">
                Recharge Amount
              </h3>
              <p>{rechargeAmount}</p>
            </span>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">GST Amount</h3>
              <p>
                {gstAmount}{" "}
                <span className="ml-2 text-red-700">
                  ({paymentConfig.rechargeGST}%)
                </span>
              </p>
            </span>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">
                Total Recharge Amount
              </h3>
              <p>{parseFloat(rechargeAmount) + parseFloat(gstAmount)}</p>
            </span>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">
                Total coins gained
              </h3>
              <p>{totalCoinsGained}</p>
            </span>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">
                Incoming conv coins now
              </h3>
              <p>
                {incomingConvCoinsNow}{" "}
                <span className="ml-2 text-red-700">
                  ({currentConvRate?.description})
                </span>
              </p>
            </span>
            <span className="grid grid-cols-2 gap-3">
              <h3 className="border-r-[0.5px] border-slate-400">
                Outgoing conv coins now
              </h3>
              <p>
                {outGoingConvCoinsNow}
                <span className="ml-2 text-red-700">
                  ({currentConvRate?.description})
                </span>
              </p>
            </span>
            <div className="text-lg text-green-800 font-bold">
              Recharge Tier
            </div>
            <DataTable
              data={coinsRechargeTier}
              columns={rechargeTierTableColumn}
              style={rechargeTierTableStyles}
            ></DataTable>
          </div>
        </DialogBody>
        <DialogFooter>
          <button
            onClick={rechargeViaPhonePeOnClick}
            className="my-button-style-1 w-36 self-end"
          >
            Recharge
          </button>
        </DialogFooter>
      </Dialog>
    </>
  );
};
export default RechargeModule;

const rechargeTierTableStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.mediumGrey,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {},
  },
};
