import React, { useState, useRef, useEffect } from "react";
import { FaStop } from "react-icons/fa";
import { FaPause } from "react-icons/fa6";
import { RiRecordCircleFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { Button } from "@material-tailwind/react";
import { v4 as uuid } from "uuid";

import {
  uploadMediaOnAWS,
  EnumFileUploadStatus,
} from "../../helpers/AWSService.js";
import { useWhatsappContext } from "../../contexts/WhatsappContextProvider.js";
import { getCurrentDateTime } from "../utils/timeStampConversion.js";

const RecordVoiceNote = ({ setRecordVoiceNote, sendMessageTo }) => {
  const { sendMediaMessage } = useWhatsappContext();

  const [isRecording, setIsRecording] = useState(false);
  const [audioURL, setAudioURL] = useState(null);
  const [isPaused, setIsPaused] = useState(false);
  const [audioLog, setAudioLog] = useState("");
  const [mediaFormData, setMediaFormData] = useState();

  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const streamRef = useRef(null);

  const audioMimeType = "audio/webm";
  const audioExt = "webm";

  const resetStates = () => {
    setIsRecording(false);
    setAudioURL(null);
    setIsPaused(false);
    setAudioLog("");
    setMediaFormData();
    audioChunksRef.current = [];
  };

  const addAudioLog = (message) => {
    setAudioLog((prevLog) => prevLog + "\n" + message);
  };

  const startRecording = async () => {
    try {
      const constraints = { audio: true, video: false };
      const options = { mimeType: audioMimeType };

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      streamRef.current = stream;
      addAudioLog("Microphone access granted");

      mediaRecorderRef.current = new MediaRecorder(stream, options);
      addAudioLog(`MediaRecorder created with mimeType: ${audioMimeType}`);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunksRef.current.push(event.data);
          addAudioLog(`Received audio chunk of size: ${event.data.size} bytes`);
        }
      };

      mediaRecorderRef.current.onstop = async () => {
        addAudioLog("MediaRecorder stopped");
        const audioBlob = new Blob(audioChunksRef.current, {
          type: audioMimeType,
        });
        const url = URL.createObjectURL(audioBlob);
        setAudioURL(url);
        addAudioLog(`Audio URL created: ${url}`);

        // Upload the file to AWS after stopping recording
        await mediaUploadOnClick(audioBlob);
      };

      mediaRecorderRef.current.start(1000);
      addAudioLog("MediaRecorder started");
      setIsRecording(true);
      audioChunksRef.current = []; // Reset audio chunks
    } catch (err) {
      addAudioLog(`Error starting recording: ${err.message}`);
      toast.error("Failed to start recording");
    }
  };

  const pauseRecordingOnClick = () => {
    if (mediaRecorderRef.current && isRecording) {
      if (!isPaused) {
        mediaRecorderRef.current.pause();
        addAudioLog("Recording paused");
        setIsPaused(true);
      } else {
        mediaRecorderRef.current.resume();
        addAudioLog("Recording resumed");
        setIsPaused(false);
      }
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsPaused(false);
      addAudioLog("Stopping MediaRecorder");
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    }
  };

  const playAudio = () => {
    if (audioURL) {
      addAudioLog("Attempting to play audio");
      const audio = new Audio(audioURL);
      audio.onloadedmetadata = () => {
        addAudioLog(`Audio duration: ${audio.duration} seconds`);
      };
      audio.onerror = (error) => {
        addAudioLog(`Error loading audio: ${error.message}`);
      };
      audio
        .play()
        .then(() => {
          addAudioLog("Audio playback started");
        })
        .catch((error) => {
          addAudioLog(`Error playing audio: ${error.message}`);
          toast.error("Failed to play audio");
        });
    } else {
      addAudioLog("No audio URL available");
      toast.error("No audio to play");
    }
  };

  const sendAudio = (event) => {
    event.preventDefault();

    if (audioURL) {
      addAudioLog("Sending audio now");
      sendMediaMessage(mediaFormData);
      setRecordVoiceNote(false);
      resetStates();
    } else {
      addAudioLog("No audio available to send");
      toast.error("No audio available to send. Please record audio first.");
    }
  };

  const mediaUploadOnClick = async (audioBlob) => {
    try {
      const uniqueId = uuid();
      const uniqueFileName =
        uniqueId + "-" + getCurrentDateTime() + "." + audioExt;

      const uploadParams = {
        fileName: uniqueFileName,
        mediaFile: audioBlob, // Use the recorded audio blob
        fileType: audioMimeType, // Use the webm MIME type
      };

      addAudioLog(`Uploading file: ${uniqueFileName}`);
      let response = await uploadMediaOnAWS(uploadParams);

      if (response === EnumFileUploadStatus.FileUploadSuccess) {
        addAudioLog("File uploaded successfully");
        let formData = {
          sendTo: sendMessageTo,
          fileName: uniqueFileName,
          fileMimeType: audioMimeType,
          fileExtension: audioExt,
        };

        console.log("formData", formData);

        setMediaFormData(formData);
        addAudioLog("Media form data prepared for sending");
      } else {
        addAudioLog("File upload on cloud failed!");
        console.error("File upload on cloud failed!");
      }
    } catch (err) {
      addAudioLog(`File upload error: ${err.message}`);
      console.error("File upload error", err);
    }
  };

  useEffect(() => {
    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  return (
    <div className="w-full mx-auto space-y-5">
      <p className="font-semibold text-xl text-center">Voice Notes Recorder</p>
      <div className="flex justify-center items-center gap-3">
        {audioURL ? (
          <div className="mx-auto space-y-2">
            <audio src={audioURL} controls />
            <div className="space-x-5">
              <Button
                variant="gradient"
                color="blue"
                size="sm"
                ripple={true}
                onClick={playAudio}
              >
                Play Recording
              </Button>
              <Button
                variant="gradient"
                color="green"
                size="sm"
                ripple={true}
                onClick={sendAudio}
              >
                Send Voice Message
              </Button>
            </div>
          </div>
        ) : isRecording ? (
          <>
            <button
              type="button"
              onClick={pauseRecordingOnClick}
              className="my-button-style-cancel p-4"
            >
              {isPaused ? (
                <RiRecordCircleFill size={32} className="text-center" />
              ) : (
                <FaPause size={32} className="text-center" />
              )}
            </button>
            <button
              type="button"
              onClick={stopRecording}
              className="my-button-style-cancel p-4"
            >
              <FaStop size={32} className="text-center" />
            </button>
          </>
        ) : (
          <button
            type="button"
            onClick={startRecording}
            className="my-button-style-cancel p-4"
          >
            <RiRecordCircleFill size={24} className="text-center" />
          </button>
        )}
      </div>
      {audioLog && audioLog.length > 10 && (
        <div className="mt-4 space-y-2">
          <h3 className="ml-2 font-semibold">Audio Log</h3>
          <pre className="bg-gray-100 p-2 rounded text-sm whitespace-pre-wrap overflow-auto max-h-[65vh]">
            {audioLog}
          </pre>
        </div>
      )}
    </div>
  );
};

export default RecordVoiceNote;
