import React, { useState, createContext, useContext } from "react";
import { toast } from "react-toastify";

import { appSettings } from "../config/appConfig";

const PaymentsContext = createContext();

export const PaymentsContextProvider = ({ children }) => {
  const baseUrl = appSettings.serverBaseUrl;

  const [allPackagePriceDetails, setAllPackagePriceDetails] = useState([]);
  const [phonePaymentDetails, setPhonePaymentDetailsById] = useState([]);
  const [isLoading, setLoading] = useState(null);

  const getAllPackagePriceDetails = async () => {
    fetch(`${baseUrl}/payment/package-price-details`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else {
          setAllPackagePriceDetails(response?.result);
        }
      })
      .catch((err) => {
        toast.error("Failed to get all product price details");
      });
  };

  const applyCouponCode = async (
    packageId,
    subscriptionType,
    currency,
    couponCode
  ) => {
    const response = await fetch(`${baseUrl}/payment/apply-coupon-code`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        couponCode,
        currency,
        subscriptionType,
        packageId,
      }),
    });

    const data = await response.json();
    return data;
  };

  const payViaPhonePe = async (
    packageId,
    subscriptionType,
    currency,
    couponCode,
    requestType,
    gstAmount
  ) => {
    const response = await fetch(`${baseUrl}/payment/pay-via-phonepe`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        couponCode,
        currency,
        subscriptionType,
        packageId,
        requestType,
        gstAmount,
      }),
    });

    const data = await response.json();
    return data;
  };

  const rechargeViaPhonePe = async (rechargeAmount, requestType) => {
    const response = await fetch(`${baseUrl}/payment/recharge-via-phonepe`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        rechargeAmount,
        requestType,
      }),
    });

    const data = await response.json();
    return data;
  };

  const phonePePaymentDetailsById = (transactionId) => {
    fetch(
      `${baseUrl}/payment/phonepe-payment-details-by-id?transactionId=${transactionId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else {
          setPhonePaymentDetailsById(response?.result);
          setLoading(1);
        }
      })
      .catch((err) => {
        toast.error("Failed to initiate payment! Please contact WaBM Support.");
      });
  };

  // TBI Later for Foreign Customers
  const payViaStripeLink = async () => {
    fetch(`${baseUrl}/payment/pay-via-phonepe`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response?.Error) {
          toast.error(response.Error);
        } else {
        }
      })
      .catch((err) => {
        toast.error("Failed to initiate payment! Please contact WaBM Support.");
      });
  };

  const getSubscriptionDetails = async () => {
    try {
      const response = await fetch(`${baseUrl}/payment/subscription-history`, {
        method: "GET",
        credentials: "include",
      });
      if (!response?.ok) {
        return [];
      } else {
        const result = await response.json();
        return result;
      }
    } catch (err) {
      console.log("getSubscriptionDetails | err ", err);
      return [];
    }
  };

  const getCoinsDetails = async () => {
    try {
      const response = await fetch(`${baseUrl}/payment/fetch-coins-details`, {
        method: "GET",
        credentials: "include",
      });
      if (!response?.ok) {
        return [];
      } else {
        const result = await response.json();
        return result;
      }
    } catch (err) {
      console.log("getCoinsDetails | err ", err);
      return [];
    }
  };

  const getCoinsRechargeTier = async () => {
    try {
      const response = await fetch(
        `${baseUrl}/payment/fetch-coins-recharge-tier`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (!response?.ok) {
        return [];
      } else {
        const result = await response.json();
        return result;
      }
    } catch (err) {
      console.log("getCoinsRechargeTier | err ", err);
      return [];
    }
  };

  return (
    <PaymentsContext.Provider
      value={{
        allPackagePriceDetails,
        phonePaymentDetails,
        isLoading,
        getAllPackagePriceDetails,
        applyCouponCode,
        payViaPhonePe,
        phonePePaymentDetailsById,
        payViaStripeLink,
        getSubscriptionDetails,
        rechargeViaPhonePe,
        getCoinsDetails,
        getCoinsRechargeTier,
      }}
    >
      {children}
    </PaymentsContext.Provider>
  );
};

export const usePaymentsContext = () => useContext(PaymentsContext);
