import React, { useState, useEffect } from "react";
import {
  Drawer,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Select,
  Option,
  Button,
  Textarea,
} from "@material-tailwind/react";
import DataTable from "react-data-table-component";
import { v4 as uuid } from "uuid";
import { BiCommentError, BiSolidMessageSquareAdd } from "react-icons/bi";
import {
  BsPersonSquare,
  BsFileImage,
  BsFileEarmarkArrowUpFill,
} from "react-icons/bs";
import { FaMicrophoneAlt } from "react-icons/fa";
import { FaCheck, FaCheckDouble } from "react-icons/fa6";
import {
  IoSendSharp,
  IoClose,
  IoReload,
  IoWarningSharp,
} from "react-icons/io5";
import { ImAttachment } from "react-icons/im";
import { viewTemplate } from "./utils/viewTemplate.js";
import {
  MdVideoLibrary,
  MdAudiotrack,
  MdOutlineContacts,
} from "react-icons/md";
import { RiTimer2Fill } from "react-icons/ri";
import { TbClockPlus } from "react-icons/tb";
import { TfiMenuAlt } from "react-icons/tfi";

import { colorsConfig } from "../config/colorsConfig";
import { uploadLimits } from "../config/appConfig";
import {
  uploadMediaOnAWS,
  EnumFileUploadStatus,
} from "../helpers/AWSService.js";
import { useConversationContext } from "../contexts/ConversationContextProvider";
import { useUserContext } from "../contexts/UserContextProvider";
import { useWhatsappContext } from "../contexts/WhatsappContextProvider";
import { timeStamp24hrComparision } from "../components/utils/timeStamp24hrComparision";
import { messageDateCheck } from "./utils/messageDateCheck";
import { viewSentMessageTemplate } from "./utils/viewSentMessageTemplate";
import { parseText } from "./utils/parseText";
import { useFollowupContext } from "../contexts/FollowupContextProvider";
import { AddNewContact, AssignedFollowup, RecordVoiceNote } from ".";
import locationPlaceholder from "../images/props/locationPlaceholder.png";
import contactsPlaceholderImage from "../images/props/contactsPlaceholderImage.png";
import locationPlaceholderImage from "../images/props/locationPlaceholderImage.png";

const ChatUi = () => {
  const {
    currentUserInfo,
    apiCallGetUserAgents,
    userAgentsList,
    isUserAdminOrSuperadmin,
  } = useUserContext();
  const { sendDirectMessage, sendMediaMessage, sendInteractiveMessage } =
    useWhatsappContext();
  const {
    chatData,
    chatContact,
    chatImage,
    setChatContact,
    setSelectedChatContactToShow,
    setChatImage,
    convGetChatMedia,
    convGetChatHistory,
    socketConnection,
    setChatHistory,
    assignChat,
    recentContactsList,
    getQuickReply,
    quickReplyList,
  } = useConversationContext();
  const { addContactFollowup } = useFollowupContext();

  const [messageValue, setMessageValue] = useState("");
  const [interactiveHeaderValue, setInteractiveHeaderValue] = useState("");
  const [interactiveBodyValue, setInteractiveBodyValue] = useState("");
  const [interactiveFooterValue, setInteractiveFooterValue] = useState("");
  const [interactiveButtonTextValue, setInteractiveButtonTextValue] =
    useState("");
  const [interactiveButtonUrlValue, setInteractiveButtonUrlValue] =
    useState("");
  const [attachment, setAttachment] = useState(false);
  const [isInteractiveMessage, setIsInteractiveMessage] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState("");
  const [mediaFormData, setMediaFormData] = useState();

  const [isAddNewContact, setIsAddNewContact] = useState(false);
  const [toAssignThisChat, setToAssignThisChat] = useState(false);
  const [toFollowUp, setToFollowUp] = useState(false);
  const [toTakeFollowUp, setTakeToFollowUp] = useState(false);
  const [toQuickReply, setToQuickReply] = useState(false);
  const [quickReplyFilterArray, setQuickReplyFilterArray] = useState([]);
  const [preSelectedAgentForChatAssign, setPreSelectedAgentForChatAssign] =
    useState([]);

  const [followupRemarks, setFollowupRemarks] = useState("");
  const [followupBy, setFollowupBy] = useState(0);
  const [followupCheck, setFollowupCheck] = useState("");

  const [recordVoiceNote, setRecordVoiceNote] = useState(false);

  const addHoursToCurrentDateTime = (hours) => {
    let date = new Date();

    // Convert hours to minutes (e.g., 2.5 hours * 60 = 150 minutes)
    const totalMinutes = hours * 60;
    date.setMinutes(date.getMinutes() + totalMinutes);
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());

    return date.toISOString().substring(0, 16);
  };

  const [selectedFollowupDateTime, setSelectedFollowupDateTime] = useState(
    addHoursToCurrentDateTime(1)
  );

  let lastReceivedMessageTimeFlag = false;
  let timeStamp24hrComparisionResult = false;
  let currentMessageDateFlag = "";
  let selectedAgentForChatAssign = [];

  const convertToLocalTime = (timestamp) => {
    const date = new Date(timestamp * 1000);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const resetOnClick = () => {
    setMessageValue("");
    setInteractiveHeaderValue("");
    setInteractiveBodyValue("");
    setInteractiveButtonTextValue("");
    setInteractiveButtonUrlValue("");
    setInteractiveFooterValue("");
    setAttachment(false);
    setIsInteractiveMessage(false);
    setSelectedMedia("");
    setMediaFormData();
    selectedAgentForChatAssign = [];
    setPreSelectedAgentForChatAssign([]);
  };

  const submitInteractiveMessage = (e) => {
    e.preventDefault();
    const sendMessageTo = chatContact?.messageData?.from
      ? chatContact?.messageData?.from
      : chatContact?.messageData?.to;
    sendInteractiveMessage(
      sendMessageTo,
      interactiveHeaderValue,
      interactiveBodyValue,
      interactiveFooterValue,
      interactiveButtonTextValue,
      interactiveButtonUrlValue
    );
    setIsInteractiveMessage(false);
    resetOnClick();
  };

  const sendDirectMessageAndReset = () => {
    const sendMessageTo = chatContact?.messageData?.from
      ? chatContact?.messageData?.from
      : chatContact?.messageData?.to;
    sendDirectMessage(sendMessageTo, messageValue);
    resetOnClick();
    setTimeout(() => convGetChatHistory(chatContact._id), 1500);
  };

  // To prevent next line when Enter is pressed and send the message
  const handleMessageSendOnKeyDown = (event) => {
    if (event.key === "Enter" && event.shiftKey === true) {
      return;
    }

    if (event.key === "Enter" && event.shiftKey === false) {
      event.preventDefault();
      sendDirectMessageAndReset();
    }
  };

  const handleMessageSendButtonClick = (event) => {
    event.preventDefault();
    sendDirectMessageAndReset();
  };

  const mediaUploadOnChange = async (event) => {
    try {
      event.preventDefault();
      const uploadedFile = event.target.files[0];
      setSelectedMedia(uploadedFile.name);

      const uniqueId = uuid();
      const uniqueFileName =
        uniqueId + "." + uploadedFile.name.split(".").pop().toLowerCase();

      const uploadParams = {
        fileName: uniqueFileName,
        mediaFile: uploadedFile,
        fileType: uploadedFile.type,
      };
      let response = await uploadMediaOnAWS(uploadParams);
      if (response === EnumFileUploadStatus.FileUploadSuccess) {
        const sendMessageTo = chatContact?.messageData?.from
          ? chatContact?.messageData?.from
          : chatContact?.messageData?.to;
        let formData = new FormData();
        formData = {
          sendTo: sendMessageTo,
          fileName: uniqueFileName,
          fileExtension: uploadedFile.name.split(".").pop().toLowerCase(),
        };
        setMediaFormData(formData);
      } else {
        console.error("File upload on cloud failed!");
      }
    } catch (err) {
      console.error("File upload error", err);
    }
  };

  const onCancelMediaUpload = () => {
    setSelectedMedia("");
    setMediaFormData();
    document.querySelector("#image-button").value = "";
  };

  const handleMediaSendOnSubmit = (event) => {
    event.preventDefault();
    sendMediaMessage(mediaFormData);
    resetOnClick();
  };

  const handleAssignChatOnSubmit = (event) => {
    event.preventDefault();
    const phoneNumber = chatContact?._id;
    assignChat(phoneNumber, selectedAgentForChatAssign);
    setToAssignThisChat(false);
    selectedAgentForChatAssign = [];
    setPreSelectedAgentForChatAssign([]);
  };

  const handleFollowUpOnSubmit = (event) => {
    event.preventDefault();

    if (followupBy === 0) {
      setFollowupCheck("Please select the Follow-Up by");
      return;
    } else if (
      followupBy > 0 &&
      chatContact?._id !== "" &&
      followupRemarks !== ""
    ) {
      addContactFollowup({
        contactNumber: chatContact?._id,
        followupBy: followupBy,
        followupOn: selectedFollowupDateTime,
        followupRemarks: followupRemarks,
      });
      setToFollowUp(false);
      setFollowupBy("");
      setFollowupRemarks("");
      setFollowupCheck("");
    } else {
      console.log("followupBy", followupBy);
      console.log("chatContact", chatContact);
      console.log("selectedFollowupDateTime", selectedFollowupDateTime);
      console.log("followupRemarks", followupRemarks);
    }
  };

  const handleScroll = (e) => {
    // Prevent scrolling from bubbling up to parent elements
    e.stopPropagation();
  };
  // ******************** View Message ********************

  const viewMessage = (message, messageType) => {
    if (message?.type === "text") {
      if (typeof message?.text?.body === "string") {
        return message?.text?.body.startsWith("REACTION")
          ? message?.text?.body.slice(-2)
          : parseText(message?.text?.body);
      }
      return "Unsupported Text Message";
    } else if (message?.type === "template") {
      return (
        <div className="mt-1 text-sm -space-y-1">
          {message?.template?.components ? (
            <>
              {message?.template?.components?.map((element) =>
                viewSentMessageTemplate(element)
              )}
              <p className="italic">Message Template</p>
              <p className="text-lg font-semibold">{message?.template?.name}</p>
              <p>{message?.template?.language?.code}</p>
            </>
          ) : (
            <>
              <p className="italic">Message Template</p>
              <p className="text-lg font-semibold">{message?.template?.name}</p>
              <p>{message?.template?.language?.code}</p>
            </>
          )}
        </div>
      );
    } else if (message?.type === "image") {
      return (
        <>
          <div className="bg-[url(../src/images/props/imagePlaceholder.png)] bg-cover relative w-[210px] h-[90px] mb-1">
            <button
              onClick={() => convGetChatMedia(message?.image?.id)}
              className="my-button-style-4 absolute top-7 right-8 p-2 hover:text-wabmGreen text-xs"
            >
              View
            </button>
          </div>
          <p>{message?.image?.caption}</p>
        </>
      );
    } else if (message?.type === "document") {
      return (
        <div className="bg-[url(../src/images/props/documentPlaceholder.png)] bg-cover relative w-[210px] h-[90px]">
          <button
            onClick={() => convGetChatMedia(message?.document?.id)}
            className="my-button-style-4 absolute top-7 right-4 p-2 hover:text-wabmGreen text-xs"
          >
            Download
          </button>
        </div>
      );
    } else if (message?.type === "audio") {
      return (
        <div className="bg-[url(../src/images/props/audioPlaceholder.png)] bg-cover relative w-[210px] h-[90px]">
          <button
            onClick={() => convGetChatMedia(message?.audio?.id)}
            className="my-button-style-4 absolute top-7 right-4 p-2 hover:text-wabmGreen text-xs"
          >
            Download
          </button>
        </div>
      );
    } else if (message?.type === "contacts") {
      return (
        <div className="space-y-2 min-w-[210px]">
          {message?.contacts?.map((contact, index) => (
            <div
              key={index}
              className="w-full flex flex-col justify-center items-center gap-2"
            >
              <div className="w-full ml-2 p-2 border-2 bg-wabmBlue bg-opacity-30 rounded-md">
                <p className="font-bold">{contact?.name?.formatted_name}</p>
                <span className="space-y-1">
                  {contact?.phones?.map((phone, idx) => (
                    <span key={idx}>
                      <p>{phone?.phone}</p>
                      <p className="text-xs">( {phone?.type} )</p>
                    </span>
                  ))}
                </span>
              </div>
              <img src={contactsPlaceholderImage} alt="my-contact" />
            </div>
          ))}
        </div>
      );
    } else if (message?.type === "video") {
      return (
        <div className="bg-[url(../src/images/props/videoPlaceholder.png)] bg-cover relative w-[210px] h-[90px]">
          <button
            onClick={() => convGetChatMedia(message?.video?.id)}
            className="my-button-style-4 absolute top-7 right-4 p-2 hover:text-wabmGreen text-xs"
          >
            Download
          </button>
        </div>
      );
    } else if (message?.type === "location") {
      if (messageType === "received") {
        const locUrl = `https://maps.google.com/maps?q=${message?.location?.latitude} ${message?.location?.longitude}&output=embed`;
        return (
          <div className="w-full flex flex-col justify-center items-center gap-2">
            <a
              href={message?.location?.url}
              target="_blank"
              rel="noopener noreferrer"
              className="w-full ml-2 p-2 bg-wabmBlue bg-opacity-30 hover:bg-opacity-70 rounded-md transition duration-300 ease-in-out"
            >
              <p className="break-words">
                {message?.location?.address}, {message?.location?.name}
              </p>
            </a>
            <iframe title="received-location" src={locUrl}></iframe>
            <img src={locationPlaceholderImage} alt="received location" />
          </div>
        );
      } else {
        return (
          <img
            src={locationPlaceholder}
            className="w-full px-1 rounded-md"
            alt="placeholder"
          />
        );
      }
    } else if (message?.type === "interactive") {
      if (messageType === "received") {
        return (
          <div>
            {message?.interactive?.type === "list_reply" ? (
              <div className="">
                <p className="italic">List Reply</p>
                <p>{message?.interactive?.list_reply?.title}</p>
                <p>{message?.interactive?.list_reply?.description}</p>
              </div>
            ) : message?.interactive?.type === "button_reply" ? (
              <div className="flex flex-col gap-y-1">
                <span className="bg-wabmBlue bg-opacity-30 px-3 py-2 text-xs rounded-md">
                  {message?.interactive?.button_reply?.title}
                </span>
                <span>{message?.interactive?.button_reply?.title}</span>
              </div>
            ) : (
              <div>Some {message?.interactive?.type} message</div>
            )}
          </div>
        );
      } else {
        return (
          <div className="">
            {message?.interactive?.type === "list" ? (
              <div className="space-y-1">
                <p className="italic">List Message</p>
                <p>{message?.interactive?.body?.text}</p>
                <p className="inline-flex items-center gap-2">
                  <TfiMenuAlt /> {message?.interactive?.action?.button}
                </p>
                {message?.interactive?.footer && (
                  <p className="text-xs">
                    {message?.interactive?.footer?.text}
                  </p>
                )}
              </div>
            ) : message?.interactive?.type === "button" ? (
              <div className="space-y-1">
                <p className="italic">Button Message</p>
                <p>{message?.interactive?.body?.text}</p>
                {message?.interactive?.action?.buttons?.map((button, index) => (
                  <div
                    key={index}
                    className="bg-white bg-opacity-30 px-3 py-2 text-xs text-center rounded-md"
                  >
                    {button?.type === "reply"
                      ? button?.reply?.title
                      : "_unknown_reply_"}
                  </div>
                ))}
              </div>
            ) : (
              <div>Some Interactive Message</div>
            )}
          </div>
        );
      }
    } else if (message?.type === "button") {
      return (
        <div className="flex flex-col gap-y-1">
          <span className="bg-wabmBlue bg-opacity-30 px-3 py-2 text-xs rounded-md">
            {message?.button && message?.button?.payload
              ? message?.button?.payload
              : "Unsupported Button"}
          </span>
          <span>
            {message?.button && message?.button?.payload
              ? message?.button?.text
              : "Unsupported Button"}
          </span>
        </div>
      );
    } else {
      return <BiCommentError size={28} color="red" />;
    }
  };

  // ******************** END OF View Message ********************

  const socketMessageListener = () => {
    convGetChatHistory(chatContact?._id);
  };

  const agentTableColumns = [
    {
      name: "Agent User Name",
      selector: (row) => row?.agentUsername,
      sortable: true,
    },
    {
      name: "Agent Name",
      selector: (row) => row?.agentFullName,
      sortable: true,
    },
    {
      name: "Agent Email",
      selector: (row) => row?.agentEmail,
      sortable: true,
    },
  ];

  const handleChange = ({ selectedRows }) => {
    selectedAgentForChatAssign = [];
    selectedRows?.map((x) => selectedAgentForChatAssign.push(x.agentId));
  };

  const preAssignedAgent = (row) =>
    preSelectedAgentForChatAssign.includes(row.agentId);

  useEffect(() => {}, [chatData]);
  useEffect(() => {}, [selectedMedia]);
  useEffect(() => {
    apiCallGetUserAgents();
    getQuickReply();
    setQuickReplyFilterArray(quickReplyList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowClick = (value) => {
    setMessageValue(value);
    setToQuickReply(false);
  };

  useEffect(() => {
    if (chatContact && socketConnection?.connected) {
      socketConnection.on(currentUserInfo?.wabPhoneId, socketMessageListener);
    }

    return () => {
      if (socketConnection) {
        socketConnection.off(
          currentUserInfo?.wabPhoneId,
          socketMessageListener
        );
      }
    };
  });

  return (
    <div className="h-[60vh] md:h-screen flex flex-col justify-between overflow-y-auto">
      {chatContact !== "" && (
        <>
          <Drawer
            placement="right"
            open={isAddNewContact}
            onClose={() => setIsAddNewContact(false)}
            size="500px"
            className="w-full p-4"
          >
            <button
              type="button"
              onClick={() => setIsAddNewContact(false)}
              className="my-button-style-cancel"
            >
              <IoClose size={18} />
            </button>
            <AddNewContact
              contactInfo={chatContact}
              setIsAddNewContact={setIsAddNewContact}
            />
          </Drawer>

          <Drawer
            placement="right"
            open={recordVoiceNote}
            onClose={() => setRecordVoiceNote(false)}
            size="500px"
            className="w-full p-4"
          >
            <button
              type="button"
              onClick={() => setRecordVoiceNote(false)}
              className="my-button-style-cancel"
            >
              <IoClose size={18} />
            </button>
            <RecordVoiceNote
              setRecordVoiceNote={setRecordVoiceNote}
              sendMessageTo={
                chatContact?.messageData?.from
                  ? chatContact?.messageData?.from
                  : chatContact?.messageData?.to
              }
            />
          </Drawer>

          <div className="bg-wabmAiBlue w-full px-3 py-2 flex justify-between items-center gap-3 text-white text-sm">
            <div className="flex items-center gap-5">
              <BsPersonSquare size={36} />
              <div>
                {chatContact?.fullName !== "" &&
                chatContact?.fullName !== undefined ? (
                  <h2 className="font-bold">{chatContact?.fullName}</h2>
                ) : chatContact?.profile?.name !== undefined ? (
                  <h3 className="text-md">( {chatContact?.profile?.name} )</h3>
                ) : (
                  <></>
                )}
                <h3>+{chatContact?._id}</h3>
              </div>
            </div>
            <div className="flex items-center gap-2">
              {(isUserAdminOrSuperadmin() ||
                (chatContact?.assignedUser &&
                  JSON.parse(chatContact?.assignedUser).indexOf(
                    currentUserInfo?.agentId
                  ) !== -1)) && (
                <button
                  title="Click to Assign Chat"
                  onClick={() => {
                    let recentChatData = recentContactsList.find(
                      (x) => x._id === chatContact?._id
                    );
                    if (
                      recentChatData &&
                      recentChatData.assignedUser &&
                      JSON.parse(recentChatData.assignedUser).length > 0
                    ) {
                      setPreSelectedAgentForChatAssign(
                        JSON.parse(recentChatData.assignedUser)
                      );
                    }
                    apiCallGetUserAgents();
                    setToAssignThisChat(true);
                  }}
                  className="my-hover-effect text-white"
                >
                  <BiSolidMessageSquareAdd size={28} />
                </button>
              )}
              {currentUserInfo?.canAccessFollowUps && (
                <button
                  title="Click to Add a Follow-Up"
                  onClick={() => setToFollowUp(true)}
                  className="my-hover-effect text-white"
                >
                  <TbClockPlus size={28} />
                </button>
              )}
              {currentUserInfo?.canAccessFollowUps && (
                <button
                  title="Check Follow-Ups"
                  onClick={() => setTakeToFollowUp(true)}
                  className="my-hover-effect text-white"
                >
                  <RiTimer2Fill size={28} />
                </button>
              )}
              {chatContact?.fullName === undefined && (
                <button
                  title="Add Contact"
                  onClick={() => setIsAddNewContact(true)}
                  className="my-hover-effect text-white"
                >
                  <MdOutlineContacts size={28} />
                </button>
              )}
            </div>
            <div className="flex items-center gap-2">
              <button
                title="Click to refresh chat"
                onClick={() => convGetChatHistory(chatContact?._id)}
                className="my-hover-effect text-white"
              >
                <IoReload size={28} />
              </button>
              <button
                title="Close chat window"
                onClick={() => {
                  setSelectedChatContactToShow("");
                  setChatContact("");
                  setChatHistory(false);
                }}
                className="my-button-style-4 hover:text-red-600 h-fit"
              >
                <IoClose size={24} />
              </button>
            </div>
          </div>

          {chatImage !== undefined &&
            chatImage !== null &&
            chatImage !== "" && (
              <div className="my-full-screen-window-style-1 flex items-center z-50">
                <img
                  src={chatImage}
                  alt="chat pic"
                  className="max-w-screen max-h-screen mx-auto"
                />
                <button
                  onClick={() => setChatImage("")}
                  className="my-button-style-cancel absolute top-5 right-5"
                >
                  <IoClose size={18} />
                </button>
              </div>
            )}

          <div className="w-full h-full p-2 flex flex-col-reverse gap-3 overflow-y-scroll">
            {chatData?.map((chat, index) => {
              const messageDateCheckValue = messageDateCheck(
                chat?.timestamp
              ).toString();
              let isDateDisplay = false;
              let timeStampToDisplay = "";
              let messageBody = [];

              if (chat?.messageBody) {
                const messageBodyJSON = JSON.parse(chat?.messageBody);
                messageBody = messageBodyJSON.components;
              }

              if (currentMessageDateFlag === "") {
                currentMessageDateFlag = messageDateCheckValue;
              } else if (currentMessageDateFlag !== messageDateCheckValue) {
                timeStampToDisplay = currentMessageDateFlag;
                isDateDisplay = true;
                currentMessageDateFlag = messageDateCheckValue;
              }

              // console.log("chat", chat);

              if (chat?.from) {
                if (lastReceivedMessageTimeFlag === false) {
                  timeStamp24hrComparisionResult = timeStamp24hrComparision(
                    chat?.timestamp
                  );
                  lastReceivedMessageTimeFlag = true;
                }

                return (
                  <React.Fragment key={chat?._id}>
                    {isDateDisplay && timeStampToDisplay !== "" && (
                      <p className="bg-slate-200 mx-auto px-2 py-1 text-xs rounded">
                        {timeStampToDisplay}
                      </p>
                    )}
                    {index < chatData?.length - 1 && (
                      <div className="flex justify-start">
                        <div className="my-message-received">
                          <p className="whitespace-pre-line mb-2">
                            {viewMessage(chat, "received")}
                          </p>
                          {messageBody?.length > 0 && (
                            <div className="bg-white rounded-md">
                              {messageBody?.map((element) =>
                                viewTemplate(element)
                              )}
                            </div>
                          )}
                          <div className="left-1 bottom-0 text-[10px] text-slate-600">
                            {convertToLocalTime(chat?.timestamp)}
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                );
              } else if (chat?.to) {
                return (
                  <React.Fragment key={chat?._id}>
                    {isDateDisplay && timeStampToDisplay !== "" && (
                      <p className="bg-slate-200 mx-auto px-2 py-1 text-xs rounded">
                        {timeStampToDisplay}
                      </p>
                    )}
                    {index < chatData?.length - 1 && (
                      <div className="relative flex justify-end">
                        <div className="my-message-sent">
                          <span
                            title={chat?.agent ? chat?.agent : "Unknown"}
                            className={`${
                              chat?.agent === currentUserInfo?.agentName
                                ? "bg-infokeyYellow text-black"
                                : "bg-wabmBlue"
                            } w-6 h-6 p-1 absolute -top-3 -right-1 text-center text-xs font-bold rounded-full z-10`}
                          >
                            {chat?.agent ? chat?.agent.charAt(0) : "!"}
                          </span>

                          <p className="whitespace-pre-line mb-2">
                            {viewMessage(chat, "sent")}
                          </p>
                          {messageBody?.length > 0 && (
                            <div className="bg-white rounded-md">
                              {messageBody?.map((element) =>
                                viewTemplate(element)
                              )}
                            </div>
                          )}
                          <div className="flex justify-between items-center gap-5 text-slate-600">
                            <p className="text-[10px]">
                              {convertToLocalTime(chat?.timestamp)}
                            </p>
                            <span>
                              {chat?.messageStatus === "sent" ? (
                                <FaCheck
                                  title="Sent"
                                  className="text-slate-400"
                                />
                              ) : chat?.messageStatus === "delivered" ? (
                                <FaCheckDouble
                                  title="Delivered"
                                  className="text-slate-400"
                                />
                              ) : chat?.messageStatus === "read" ? (
                                <FaCheckDouble
                                  title="Read"
                                  className="text-green-500"
                                />
                              ) : chat?.messageStatus === "failed" ? (
                                <IoClose
                                  title={`${
                                    chat?.errorMessage
                                      ? chat?.errorMessage
                                      : "Failed"
                                  }`}
                                  className="text-red-600"
                                />
                              ) : (
                                <IoWarningSharp
                                  title="Status Unknown"
                                  className="text-amber-400"
                                />
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                );
              }
              return <></>;
            })}
          </div>

          {timeStamp24hrComparisionResult ? (
            <div className="bg-wabmBlue w-full p-2 relative flex gap-2">
              {(chatContact?.assignedUser &&
                JSON.parse(chatContact?.assignedUser).indexOf(
                  currentUserInfo?.agentId
                ) !== -1) ||
              currentUserInfo?.isAdmin === 1 ||
              currentUserInfo?.isAdmin === 2 ? (
                <>
                  {attachment ? (
                    <button
                      type="button"
                      onClick={() => setAttachment(!attachment)}
                      className="my-button-style-1 h-full text-wabmGreen"
                    >
                      <ImAttachment />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={() => setAttachment(!attachment)}
                      className="my-button-style-1 h-full"
                    >
                      <ImAttachment />
                    </button>
                  )}
                  <button
                    onClick={() => setRecordVoiceNote(true)}
                    className="my-button-style-1"
                  >
                    <FaMicrophoneAlt />
                  </button>
                  {attachment ? (
                    <div className="w-full h-10 flex justify-between">
                      <form
                        onSubmit={handleMediaSendOnSubmit}
                        className="flex gap-2"
                      >
                        {selectedMedia !== "" && (
                          <div className="my-card-style-2 absolute bottom-12 left-0 z-50 flex justify-between items-center gap-5">
                            <h3 className="text-white font-bold">
                              {selectedMedia}
                            </h3>
                            <span className="flex gap-2">
                              <button
                                onClick={onCancelMediaUpload}
                                className="my-button-style-4 px-3"
                              >
                                Cancel
                              </button>
                              <button
                                type="submit"
                                className="my-button-style-1"
                              >
                                <IoSendSharp />
                              </button>
                            </span>
                          </div>
                        )}
                        <input
                          accept="image/png, image/jpeg"
                          max={uploadLimits.image}
                          id="image-button"
                          type="file"
                          onChange={mediaUploadOnChange}
                          className="hidden"
                        />
                        <label
                          htmlFor="image-button"
                          className="my-button-style-3 px-3 py-2 grid place-items-center hover:bg-wabmGreen"
                        >
                          <BsFileImage />
                        </label>

                        <input
                          accept="video/mp4, video/3gp"
                          max={uploadLimits.video}
                          id="video-button"
                          type="file"
                          onChange={mediaUploadOnChange}
                          className="hidden"
                        />
                        <label
                          htmlFor="video-button"
                          className="my-button-style-3 px-3 py-2 grid place-items-center hover:bg-wabmGreen"
                        >
                          <MdVideoLibrary />
                        </label>

                        <input
                          accept="audio/aac, audio/mp4, audio/mpeg, audio/amr, audio/ogg"
                          max={uploadLimits.audio}
                          id="audio-button"
                          type="file"
                          onChange={mediaUploadOnChange}
                          className="hidden"
                        />
                        <label
                          htmlFor="audio-button"
                          className="my-button-style-3 px-3 py-2 grid place-items-center hover:bg-wabmGreen"
                        >
                          <MdAudiotrack />
                        </label>

                        <input
                          accept="text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          max={uploadLimits.document}
                          id="file-button"
                          type="file"
                          onChange={mediaUploadOnChange}
                          className="hidden"
                        />
                        <label
                          htmlFor="file-button"
                          className="my-button-style-3 px-3 py-2 grid place-items-center hover:bg-wabmGreen"
                        >
                          <BsFileEarmarkArrowUpFill />
                        </label>
                        <button
                          type="button"
                          onClick={() => {
                            setInteractiveBodyValue("");
                            setInteractiveHeaderValue("");
                            setInteractiveFooterValue("");
                            setInteractiveButtonTextValue("");
                            setInteractiveButtonUrlValue("");
                            setIsInteractiveMessage(true);
                          }}
                          className="my-button-style-3 px-3 py-2 hover:bg-wabmGreen"
                        >
                          Send Interactive Message
                        </button>
                        <button
                          onClick={() => setAttachment(false)}
                          className="my-button-style-3 px-3 py-2 hover:bg-wabmGreen"
                        >
                          Type a Message
                        </button>
                      </form>
                    </div>
                  ) : (
                    <form className="w-full flex gap-2">
                      <textarea
                        cols="25"
                        rows="1"
                        value={messageValue}
                        onChange={(e) => {
                          if (/^\/\S*$/.test(e.target.value)) {
                            setToQuickReply(true);
                            if (quickReplyList && quickReplyList.length > 0) {
                              let filterQuickReply = quickReplyList?.filter(
                                (el) =>
                                  el.quickReplyText
                                    .toLowerCase()
                                    .includes(
                                      e.target.value
                                        .toLowerCase()
                                        .replace("/", "")
                                    )
                              );
                              setQuickReplyFilterArray(filterQuickReply);
                            }
                          } else {
                            setToQuickReply(false);
                          }
                          setMessageValue(e.target.value);
                        }}
                        placeholder="type your message"
                        onKeyDown={handleMessageSendOnKeyDown}
                        className="my-input-style-1 py-1 w-full"
                      />
                      <button
                        onClick={handleMessageSendButtonClick}
                        className="my-button-style-1"
                      >
                        <IoSendSharp />
                      </button>
                    </form>
                  )}
                </>
              ) : (
                <div className="bg-wabmBlue w-full p-2 relative flex gap-2">
                  <p className="text-xs text-white">
                    You don't have permission to reply on this chat.
                  </p>
                </div>
              )}
            </div>
          ) : (
            <div className="bg-wabmAiBlue w-full p-2 relative flex gap-2">
              <p className="text-xs text-white">
                It's been more than 24 hrs since last received message. Please
                initiate the conversation with templates.
              </p>
            </div>
          )}
        </>
      )}

      <Dialog
        open={toAssignThisChat}
        handler={() => setToAssignThisChat(!toAssignThisChat)}
      >
        <span className="flex justify-between gap-3">
          <DialogHeader>Assign Chat</DialogHeader>
        </span>
        <form onSubmit={handleAssignChatOnSubmit}>
          <DialogBody className="space-y-2">
            <div className="my-2 rounded-md">
              <DataTable
                columns={agentTableColumns}
                data={userAgentsList?.filter((x) => x.isBlocked === 0)}
                customStyles={agentTableStyles}
                fixedHeader
                selectableRows
                onSelectedRowsChange={handleChange}
                fixedHeaderScrollHeight="60vh"
                selectableRowSelected={preAssignedAgent}
              />
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              type="button"
              variant="text"
              color="red"
              onClick={() => {
                setToAssignThisChat(false);
              }}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button type="submit" variant="gradient" color="green">
              <span>Confirm</span>
            </Button>
          </DialogFooter>
        </form>
      </Dialog>
      <Dialog
        className="w-72"
        open={isInteractiveMessage}
        handler={() => setIsInteractiveMessage(!isInteractiveMessage)}
      >
        <span className="flex justify-between gap-2">
          <DialogHeader>Send Interactive Message</DialogHeader>
        </span>
        <form onSubmit={submitInteractiveMessage}>
          <DialogBody>
            <div className="flex flex-row justify-start">
              <div className="flex flex-col justify-start gap-3 m-2 w-[60%]">
                <Input
                  value={interactiveHeaderValue}
                  onChange={(e) => {
                    setInteractiveHeaderValue(e.target.value);
                  }}
                  type="text"
                  variant="outlined"
                  color="green"
                  className={`${
                    interactiveHeaderValue === ""
                      ? "outline-2 outline-green-700"
                      : ""
                  }`}
                  label="Header"
                />
                <Textarea
                  value={interactiveBodyValue}
                  onChange={(e) => setInteractiveBodyValue(e.target.value)}
                  type="text"
                  required
                  variant="outlined"
                  color="green"
                  label="Body"
                  className={`${
                    interactiveBodyValue === "" ? "outline-2" : ""
                  } outline-green-700`}
                />
                <Input
                  value={interactiveFooterValue}
                  onChange={(e) => setInteractiveFooterValue(e.target.value)}
                  type="text"
                  variant="outlined"
                  color="green"
                  label="Footer"
                  className={`${
                    interactiveFooterValue === "" ? "outline-2" : ""
                  } outline-green-700`}
                />
                <Input
                  value={interactiveButtonTextValue}
                  onChange={(e) =>
                    setInteractiveButtonTextValue(e.target.value)
                  }
                  type="text"
                  required
                  variant="outlined"
                  color="green"
                  label="Button Text"
                  className={`${
                    interactiveButtonTextValue === "" ? "outline-2" : ""
                  } outline-green-700`}
                />
                <Input
                  value={interactiveButtonUrlValue}
                  onChange={(e) => setInteractiveButtonUrlValue(e.target.value)}
                  type="text"
                  required
                  variant="outlined"
                  color="green"
                  label="Button URL"
                  className={`${
                    interactiveButtonUrlValue === "" ? "outline-2" : ""
                  } outline-green-700`}
                />
              </div>
              <div className="flex flex-col justify-start gap-3 m-2 w-[38%]">
                <div className="flex flex-col justify-center items-center bg-gray-100 m-4 w-full h-full rounded-lg">
                  <div>{interactiveHeaderValue}</div>
                  <div>{interactiveBodyValue}</div>
                  <div className="text-gray-400">{interactiveFooterValue}</div>
                  {interactiveButtonTextValue !== "" && (
                    <div className="mt-4">
                      <a
                        className="border-2 border-green-600 rounded-md p-3"
                        href={interactiveButtonUrlValue}
                      >
                        {interactiveButtonTextValue}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              type="button"
              variant="filled"
              color="red"
              onClick={() => setIsInteractiveMessage(false)}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>

            <Button type="submit" variant="gradient" color="blue">
              <IoSendSharp />
            </Button>
          </DialogFooter>
        </form>
      </Dialog>

      <Dialog
        className="w-72"
        open={toFollowUp}
        handler={() => setToFollowUp(!toFollowUp)}
      >
        <span className="flex justify-between gap-2">
          <DialogHeader>Want to add a Follow Up?</DialogHeader>
        </span>
        <form onSubmit={handleFollowUpOnSubmit}>
          <DialogBody>
            <div className="flex flex-col justify-start gap-4">
              <Select
                label="Follow-Up By"
                variant="standard"
                color="blue"
                required
                valid={followupBy !== 0}
                onChange={(value) => setFollowupBy(value)}
                className="my-input-box-1"
              >
                {userAgentsList
                  ?.filter((x) => x?.isBlocked === 0)
                  .map((agent) => (
                    <Option
                      key={agent?.agentId}
                      value={agent?.agentId}
                      className={`hover:bg-blue-300 focus:bg-blue-300`}
                    >
                      {agent?.agentFullName + " (" + agent?.agentUsername + ")"}
                    </Option>
                  ))}
              </Select>
              <Input
                variant="standard"
                label="Note"
                placeholder="Eg. Check if customer has seen your proposal"
                required
                onChange={(e) => setFollowupRemarks(e.target.value)}
              />
              <span className="grid grid-cols-2 gap-3">
                <p className="border-r border-slate-400 text-sm">
                  Schedule Time
                </p>
                <input
                  type="datetime-local"
                  required
                  value={selectedFollowupDateTime}
                  onChange={(event) => {
                    setSelectedFollowupDateTime(event.target.value);
                  }}
                  min={addHoursToCurrentDateTime(0.5)}
                  max={addHoursToCurrentDateTime(1080)}
                  className="my-input-style-1"
                />
              </span>
              {followupCheck !== "" && (
                <p className="text-xs text-red-500">{followupCheck}</p>
              )}
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              type="button"
              variant="text"
              color="red"
              onClick={() => setToFollowUp(false)}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button type="submit" variant="gradient" color="green">
              <span>Confirm</span>
            </Button>
          </DialogFooter>
        </form>
      </Dialog>

      <Drawer
        onScroll={handleScroll}
        placement="bottom"
        open={toTakeFollowUp}
        onClose={() => setTakeToFollowUp(!toTakeFollowUp)}
        size="91vh"
        className="rounded-t-xl z-[9996]"
      >
        <AssignedFollowup contactNumber={chatContact?._id} />
      </Drawer>

      {toQuickReply && (
        <div
          className={`mb-2 mt-16 bg-zinc-50 z-[8888] absolute w-[66%] h-[84%] transition-transform duration-500 ${
            toQuickReply ? "translate-y-0" : "-translate-y-full"
          }`}
        >
          <div className="flex flex-col justify-start p-4 gap-1">
            <h1 className="text-2xl mb-3">Quick Reply</h1>
            {quickReplyFilterArray?.length > 0 &&
              quickReplyFilterArray?.map((element) => (
                <div
                  onClick={() => handleRowClick(element?.quickReplyText)}
                  className="bg-zinc-100 rounded p-2 hover:bg-blue-200 
                hover:cursor-pointer"
                >
                  {element?.quickReplyText}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatUi;

const agentTableStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.mediumGrey,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {},
  },
};
