import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Button,
  Checkbox,
} from "@material-tailwind/react";

import { colorsConfig } from "../../config/colorsConfig";
import { useConversationContext } from "../../contexts/ConversationContextProvider";
import { useUserContext } from "../../contexts/UserContextProvider";
import { SeoTags } from "..";

const ManageQuickReply = () => {
  const [isAddQuickReply, setIsAddQuickReply] = useState(false);
  const [quickReplyText, setQuickReplyText] = useState("");
  const [isAgentView, setIsAgentView] = useState(false);
  const [isAdminView, setIsAdminView] = useState(false);
  const [isConfirmBox, setIsConfirmBox] = useState(false);
  const [isDeleteConfirmBox, setIsDeleteConfirmBox] = useState(false);
  const [quickReplyId, setQuickReplyId] = useState("");
  const {
    saveQuickReply,
    getQuickReply,
    quickReplyList,
    pendingApprovalQuickReply,
    getPendingApproval,
    approveQuickReply,
    deleteQuickReply,
  } = useConversationContext();
  const { currentUserInfo } = useUserContext();

  const handleAddQuickReply = (event) => {
    event.preventDefault();
    saveQuickReply({
      quickReplyText: quickReplyText,
      allowAllAgentsToView: isAgentView ? 1 : 0,
      allowAllAdminToView: isAdminView ? 1 : 0,
    });
    setIsAddQuickReply(false);
  };

  const quickReplyTableColumn = [
    {
      name: "Quick reply text",
      selector: (row) => row?.quickReplyText,
      sortable: true,
      wrap: true,
      grow: 4,
    },
    {
      name: "View to admin",
      selector: (row) => {
        return row?.allowAllAdminToView === 1 ? "Yes" : "No";
      },
      sortable: true,
    },
    {
      name: "View to agent",
      selector: (row) => {
        return row?.allowAllAgentsToView === 1 ? "Yes" : "No";
      },
      sortable: true,
    },
    {
      name: "Approved status",
      selector: (row) => {
        return row?.isApproved === 1 ? (
          <span className="text-green-600">Approved</span>
        ) : (
          <span className="text-red-600">Pending</span>
        );
      },
    },
    {
      name: "Approved date",
      selector: (row) => {
        if (row?.approvedDate && row?.approvedDate) {
          const approvedDate = new Date(row?.approvedDate);
          return approvedDate.toISOString().substring(0, 16).replace("T", " ");
        } else {
          return <></>;
        }
      },
      sortable: true,
    },
    {
      name: "Approved by",
      selector: (row) => row?.approvedByUser,
      sortable: true,
    },
    {
      name: "Created by",
      selector: (row) => row?.createdByUser,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <Button
            color="red"
            variant="outlined"
            onClick={() => {
              setQuickReplyId(row?._id);
              setIsDeleteConfirmBox(true);
            }}
          >
            Delete
          </Button>
        );
      },
    },
  ];

  const quickReplyPendingApproveTableColumn = [
    {
      name: "Quick reply text",
      selector: (row) => row?.quickReplyText,
      sortable: true,
    },
    {
      name: "View to admin",
      selector: (row) => {
        return row?.allowAllAdminToView === 1 ? "Yes" : "No";
      },
      sortable: true,
    },
    {
      name: "View to agent",
      selector: (row) => {
        return row?.allowAllAgentsToView === 1 ? "Yes" : "No";
      },
      sortable: true,
    },
    {
      name: "Approved status",
      selector: (row) => {
        return row?.isApproved === 1 ? (
          <span className="text-green-600">Approved</span>
        ) : (
          <span className="text-red-600">Pending</span>
        );
      },
    },
    {
      name: "Approved date",
      selector: (row) => {
        if (row?.approvedDate && row?.approvedDate) {
          const approvedDate = new Date(row?.approvedDate);
          return approvedDate.toISOString().substring(0, 16).replace("T", " ");
        } else {
          return <></>;
        }
      },
      sortable: true,
    },

    {
      name: "Approved by",
      selector: (row) => row?.approvedByUser,
      sortable: true,
    },
    {
      name: "Created by",
      selector: (row) => row?.createdByUser,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <Button
            color="green"
            variant="outlined"
            onClick={() => {
              setQuickReplyId(row?._id);
              setIsConfirmBox(true);
            }}
          >
            Approve
          </Button>
        );
      },
    },
  ];

  const handleQuickReply = () => {
    approveQuickReply({
      id: quickReplyId,
      approvedStatus: 1,
    });
    setQuickReplyId("");
    setIsConfirmBox(false);
  };

  const handleDeleteQuickReply = () => {
    deleteQuickReply(quickReplyId);
    setQuickReplyId("");
    setIsDeleteConfirmBox(false);
    getQuickReply();
  };

  useEffect(() => {
    getPendingApproval();
    getQuickReply();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="w-full mt-5 mb-10 lg:my-1 space-y-5">
      <SeoTags title="Quick Reply ‣ WaBM" />

      <div className="w-full space-y-2">
        <h1 className="text-2xl">Manage All Quick Replies</h1>
        <h3>These are details about all your saved quick reply</h3>
      </div>

      <Button
        type="button"
        variant="gradient"
        color="blue"
        onClick={() => {
          setIsAddQuickReply(true);
        }}
        className="mr-1"
      >
        Create Quick Reply
      </Button>

      <div className="wbm-card-3 space-y-3">
        <h2 className="font-Montserrat text-lg">Created quick replies</h2>
        <div className="rounded-md overflow-auto">
          <DataTable
            columns={quickReplyTableColumn}
            data={quickReplyList?.filter(
              (x) =>
                (currentUserInfo?.isAdmin === 2 && x.isApproved === 1) || // Superadmin
                (currentUserInfo?.isAdmin === 1 && // Admin
                  (x.createdBy === currentUserInfo?.agentId ||
                    x.approvedBy === currentUserInfo?.agentId) &&
                  (x.isApproved === 1 || x.isApproved === 0)) ||
                (currentUserInfo?.isAdmin === 0 && // Agent
                  x.createdBy === currentUserInfo?.agentId &&
                  (x.isApproved === 1 || x.isApproved === 0))
            )}
            customStyles={tableStyles}
            fixedHeader
            pagination
          />
        </div>
      </div>

      <div className="wbm-card-3 space-y-3">
        <h2 className="font-Montserrat text-lg">Pending for Approval</h2>
        <div className="rounded-md overflow-auto">
          {currentUserInfo?.isAdmin === 1 || currentUserInfo?.isAdmin === 2 ? (
            <DataTable
              columns={quickReplyPendingApproveTableColumn}
              data={pendingApprovalQuickReply}
              customStyles={tableStyles}
              fixedHeader
              pagination
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      <Dialog
        className="w-72"
        open={isAddQuickReply}
        handler={() => setIsAddQuickReply(!isAddQuickReply)}
      >
        <span className="flex justify-between gap-2">
          <DialogHeader>Want to add a quick reply?</DialogHeader>
        </span>
        <form onSubmit={handleAddQuickReply}>
          <DialogBody>
            <div className="flex flex-col justify-start gap-4">
              <Input
                onChange={(e) => {
                  setQuickReplyText(e.target.value);
                }}
                required
                variant="standard"
                label="Quick Reply Text"
              />
              <div className="flex flex-row justify-start gap-4">
                <Checkbox
                  onClick={(e) => {
                    setIsAgentView(!isAgentView);
                  }}
                  color="green"
                  label="Allow all agents to view"
                />
                <Checkbox
                  onClick={(e) => {
                    setIsAdminView(!isAdminView);
                  }}
                  color="green"
                  label="Allow all admin to view"
                />
              </div>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              type="button"
              variant="outline"
              color="red"
              onClick={() => {
                setIsAddQuickReply(false);
              }}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button type="submit" variant="filled" color="green">
              <span>Confirm</span>
            </Button>
          </DialogFooter>
        </form>
      </Dialog>
      <Dialog
        size={"xs"}
        open={isConfirmBox}
        handler={() => setIsConfirmBox(!isConfirmBox)}
      >
        <DialogHeader>
          <h3 className="font-normal">Approve Quick Reply</h3>
        </DialogHeader>
        <DialogBody>
          <h3>Do you want to approve ?</h3>
        </DialogBody>
        <DialogFooter className="flex flex-row justify-end gap-5">
          <Button
            color="red"
            variant="outlined"
            onClick={() => setIsConfirmBox(false)}
          >
            Cancel
          </Button>
          <Button color="green" variant="filled" onClick={handleQuickReply}>
            Confirm
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog
        size={"xs"}
        open={isDeleteConfirmBox}
        handler={() => setIsDeleteConfirmBox(!isDeleteConfirmBox)}
      >
        <DialogHeader>
          <h3 className="font-normal">Delete Quick Reply</h3>
        </DialogHeader>
        <DialogBody>
          <h3>Do you want to Delete ?</h3>
        </DialogBody>
        <DialogFooter className="flex flex-row justify-end gap-5">
          <Button
            color="red"
            variant="outlined"
            onClick={() => setIsDeleteConfirmBox(false)}
          >
            Cancel
          </Button>
          <Button
            color="green"
            variant="filled"
            onClick={() => handleDeleteQuickReply()}
          >
            Confirm
          </Button>
        </DialogFooter>
      </Dialog>
    </main>
  );
};
export default ManageQuickReply;

const tableStyles = {
  rows: {
    style: {},
  },
  headCells: {
    style: {
      backgroundColor: colorsConfig.mediumGrey,
      color: "#FFFFFF",
      fontSize: "0.9rem",
    },
  },
  cells: {
    style: {},
  },
};
